export const useDetails = () => {
  const getDetailKeyTranslation = (detailKeyMayBeRef, localeMayBeRef) => {
    const detailKey = unref(detailKeyMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!detailKey) {
      return null
    }
    if (
      detailKey.detailsKeysTranslations &&
      detailKey.detailsKeysTranslations.length
    ) {
      const detailKeyTranslation = detailKey.detailsKeysTranslations.find(
        (translation) => translation.locales.code === locale
      )
      if (detailKeyTranslation) {
        return detailKeyTranslation
      } else if (locale !== 'en') {
        const alternateTranslation = detailKey.detailsKeysTranslations.find(
          (translation) => translation.locales.code === 'en'
        )
        if (alternateTranslation) {
          return alternateTranslation
        }
      }
    }
    return {
      key: detailKey.key.charAt(0).toUpperCase() + detailKey.key.slice(1),
    }
  }

  const getDetailsByKey = (detailsMayBeRef, keyMayBeRef, localeMayBeRef) => {
    const details = unref(detailsMayBeRef)
    const key = unref(keyMayBeRef)
    const locale = unref(localeMayBeRef) // for future use on translations
    if (details && details.length && key) {
      const filteredDetails = details.filter((detail) => detail.key === key)
      return filteredDetails
    }
    return null
  }

  const getDetailsBySegmentKey = (detailsMayBeRef, segmentKeyMayBeRef) => {
    const details = unref(detailsMayBeRef)
    const segmentKey = unref(segmentKeyMayBeRef)
    if (details && details.length && segmentKey) {
      const segmentStore = useSegmentStore()
      const segment = segmentStore.getSegmentByKey(segmentKey)
      if (segment && details && details.length > 0) {
        const filteredDetails = details.filter(
          (detail) => detail.segmentId === segment.id
        )
        return filteredDetails
      }
    }
    return null
  }

  const hasDetailsBeSegmentKey = (detailsMayBeRef, segmentKeyMayBeRef) => {
    const details = unref(detailsMayBeRef)
    const segmentKey = unref(segmentKeyMayBeRef)
    if (details && details.length && segmentKey) {
      const detailsBySegmentKey = getDetailsBySegmentKey(details, segmentKey)
      if (detailsBySegmentKey && detailsBySegmentKey.length) {
        return true
      }
    }
    return false
  }

  return {
    getDetailKeyTranslation,
    getDetailsByKey,
    getDetailsBySegmentKey,
    hasDetailsBeSegmentKey,
  }
}
