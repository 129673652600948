<template>
  <div
    ref="detailSheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-x-hidden pointer-events-auto sm:h-full overscroll-y-none"
    :style="xs ? 'height: ' + height + 'px;' : ''"
    :class="isDragging ? 'overflow-y-hidden' : 'overflow-y-auto'"
  >
    <div class="sticky top-0">
      <div class="relative bg-panel-background/90">
        <img
          v-if="mediumUrl"
          :src="mediumUrl"
          alt=""
          class="w-full h-48 object-cover pointer-events-none"
        />
        <div v-else class="h-10">&nbsp;</div>
        <div ref="detailMoveHandle" class="absolute w-full h-full top-0"></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between">
            <div
              v-if="
                selectedListId ||
                showFeedResult ||
                searchText ||
                selectedHighlightId
              "
              class="p-2 pointer-events-auto"
            >
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  ' w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="$router.go(-1)"
              >
                <SvgIcon
                  name="chevron-left"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
            <div v-else class="p-2 w-8">&nbsp;</div>
            <div class="p-2">
              <div
                :class="[
                  mediumUrl
                    ? 'border-panel-nav-control-background-medium'
                    : 'border-panel-nav-control-background',
                  'w-12 h-0 border-2  bg-opacity-60 rounded sm:hidden',
                ]"
              >
                &nbsp;
              </div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
          <div v-if="mediumUrl" class="flex flex-row justify-between">
            <div class="p-2">
              <span
                v-if="showTitleOverImage"
                class="text-white text-2xl font-bold drop-shadow-[0_1.4px_1.4px_rgba(0,0,0,0.8)]"
                >{{ itemTitle }}</span
              >
            </div>
            <div class="p-2">
              <button
                v-if="false"
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
              >
                <SvgIcon
                  name="photo"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!mediumUrl"
          class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
        >
          <h1>{{ itemTitle }}</h1>
        </div>
      </div>
    </div>
    <div
      v-if="mediumUrl"
      class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
    >
      <h1>{{ itemTitle }}</h1>
    </div>

    <div class="px-3 pb-3 text-panel-primary-text text-sm font-normal">
      <span v-for="(tag, index) in subTitleTags" :key="index">
        <span v-if="index > 0"> &middot; </span>
        {{ tag.short }}
      </span>
      <span v-if="newCategorySuggestion">
        <span v-if="subTitleTags.length > 0"> &middot; </span>
        {{ newCategorySuggestion }}
      </span>
      <span v-if="false"> &middot; 3 Veranstaltungen</span>
      <span v-if="openClosedOrChangeShort">
        &middot; {{ openClosedOrChangeShort }}
      </span>
    </div>
    <AlertBox
      v-if="permanentlyClosedText"
      :title="permanentlyClosedText"
      theme="warning"
      class="mx-3 mb-3 mt-1"
      is-compact="true"
      accent-bg="true"
    />
    <div class="px-3 pb-3 text-sm font-normal text-panel-primary-text">
      {{ itemShortDescription }}
    </div>
    <div
      v-if="nonZeroStats.length > 0"
      class="mx-3 py-3 text-panel-primary-text text-sm font-medium border-y border-panel-hr-border"
    >
      <span v-for="(stat, index) in nonZeroStats" :key="index">
        <span v-if="index > 0"> &middot; </span>
        <span class="font-bold text-panel-accent-text">{{ stat.count }}</span>
        {{ stat.name }}
      </span>
    </div>
    <div class="h-2">&nbsp;</div>
    <div
      class="grid grid-cols-5 justify-evenly justify-items-center items-center pt-2 pb-2 px-2 sm:px-4 sticky bg-panel-background/90 gap-y-1.5"
      :class="mediumUrl ? 'top-[192px]' : 'top-[92px]'"
    >
      <div :class="isSupported ? 'cursor-pointer' : ''" @click="startShare()">
        <SvgIcon
          name="arrow-up-on-square"
          class="w-7 h-7"
          :class="
            isSupported
              ? 'stroke-panel-action hover:stroke-panel-action-hover'
              : 'stroke-panel-action-disabled opacity-60'
          "
        />
      </div>
      <div class="relative cursor-pointer" @click="clickCheckin()">
        <SvgIcon
          name="check"
          class="w-7 h-7"
          :class="
            isQuoPro
              ? 'stroke-panel-action hover:stroke-panel-action-hover'
              : 'stroke-panel-action-disabled opacity-60'
          "
        />
        <QuoProIcon
          v-if="!isQuoPro"
          class="absolute -top-1 -right-3"
          size="xs"
        />
      </div>
      <div class="relative cursor-pointer" @click="clickToggleLike()">
        <SvgIcon
          v-if="isQuoPro && likeState === 'none'"
          name="heart"
          class="w-7 h-7 stroke-pink-500"
        />
        <SvgIcon
          v-if="isQuoPro && likeState === 'like'"
          name="heart"
          type="solid"
          class="w-7 h-7 fill-pink-500"
        />
        <SvgIcon
          v-if="isQuoPro && likeState === 'unlike'"
          name="heart-slash"
          type="solid"
          class="w-7 h-7 fill-gray-400"
        />
        <SvgIcon
          v-if="!isQuoPro"
          name="heart"
          class="w-7 h-7 stroke-panel-action-disabled opacity-60"
        />
        <QuoProIcon
          v-if="!isQuoPro"
          class="absolute -top-1 -right-3"
          size="xs"
        />
      </div>
      <div class="relative cursor-pointer" @click="clickMention()">
        <SvgIcon
          name="chat-bubble-left"
          class="w-7 h-7"
          :class="
            isQuoPro
              ? 'stroke-panel-action hover:stroke-panel-action-hover'
              : 'stroke-panel-action-disabled opacity-60'
          "
        />
        <QuoProIcon
          v-if="!isQuoPro"
          class="absolute -top-1 -right-3"
          size="xs"
        />
      </div>
      <div
        :class="isAuthenticated ? 'cursor-pointer' : ''"
        @click="
          isAuthenticated &&
            controllerStore.openBottomSheetList(selectedItem.id)
        "
      >
        <SvgIcon
          name="plus"
          class="w-7 h-7"
          :class="
            isAuthenticated
              ? 'stroke-panel-action hover:stroke-panel-action-hover'
              : 'stroke-panel-action-disabled opacity-60'
          "
        />
      </div>
      <!-- 2nd row -->
      <div
        class="text-xs"
        :class="
          isSupported
            ? 'text-panel-action curser-pointer'
            : 'text-panel-action-disabled'
        "
        @click="startShare()"
      >
        Share
      </div>
      <div
        class="text-xs cursor-pointer"
        :class="isQuoPro ? 'text-panel-action' : 'text-panel-action-disabled'"
        @click="clickCheckin()"
      >
        Check-in
      </div>
      <div
        class="text-xs cursor-pointer"
        :class="isQuoPro ? 'text-panel-action' : 'text-panel-action-disabled'"
        @click="clickToggleLike()"
      >
        Like
      </div>
      <div
        class="text-xs cursor-pointer"
        :class="isQuoPro ? 'text-panel-action' : 'text-panel-action-disabled'"
        @click="clickMention()"
      >
        Note
      </div>
      <div
        class="text-xs"
        :class="
          isAuthenticated
            ? 'text-panel-action cursor-pointer'
            : 'text-panel-action-disabled'
        "
        @click="
          isAuthenticated &&
            controllerStore.openBottomSheetList(selectedItem.id)
        "
      >
        Save
      </div>
      <!-- 3rd row -->
      <NuxtLink
        v-if="!isAuthenticated && !xs"
        class="col-span-5 text-xs text-center py-2 text-panel-primary-text underline"
        :to="
          localePath({
            name: 'auth-sign-in',
            query: { redirect: fullPath },
          })
        "
      >
        <span class="font-bold">Login</span> to enable action buttons
      </NuxtLink>
      <NuxtLink
        v-if="isAuthenticated && !isQuoPro && !xs"
        class="col-span-5 text-xs text-slate-400 mt-2 px-2 py-1 w-full text-center"
        :to="
          localePath({
            name: 'auth-checkout-choose-product',
            query: { chosenproduct: 'quo-pro', redirect: fullPath },
          })
        "
      >
        <span class="underline inline"
          ><span class="font-bold">Upgrade</span> to Pro</span
        >
      </NuxtLink>
    </div>
    <!-- 2rd row mobile -->
    <!-- 3rd row mobile -->
    <div v-if="!isAuthenticated && xs" class="px-2 sm:px-4">
      <NuxtLink
        :to="
          localePath({
            name: 'auth-sign-in',
            query: { redirect: fullPath },
          })
        "
      >
        <div class="text-sm text-panel-primary-text underline py-1 text-center">
          <span class="font-bold">Login</span> to enable action buttons
        </div>
      </NuxtLink>
    </div>

    <div class="h-4">&nbsp;</div>
    <!-- mentions -->
    <div class="mb-5">
      <div
        v-for="(mention, index) in mentions"
        :key="index"
        class="mx-3 my-1 px-2 pt-3 pb-2 bg-panel-mention-card-background rounded-lg"
      >
        <div class="flex flex-row">
          <div class="flex-none">
            <img
              class="inline-block h-12 w-12 rounded-full mr-2 object-cover"
              :src="mention.profile.fullAvatarUrl"
              alt=""
            />
          </div>
          <div class="grow flex flex-col text-sm min-w-0">
            <div class="flex flex-row min-w-0 overflow-hidden">
              <div class="flex flex-auto w-10/12 whitespace-nowrap">
                <div class="font-bold text-panel-accent-text">
                  {{ mention.profile.fullName }}
                </div>
                <div class="min-w-5 mx-0.5">
                  <SvgIcon
                    name="check-badge"
                    type="solid"
                    class="w-5 h-5 fill-violet-600"
                  />
                </div>
                <div class="text-panel-secondary-text truncate">
                  @{{ mention.profile.username }}
                </div>
              </div>
              <div v-if="false" class="flex-none">
                <SvgIcon
                  name="ellipsis-vertical"
                  type="solid"
                  class="w-6 h-6 fill-panel-action"
                />
              </div>
            </div>
            <div class="my-2 text-panel-primary-text text-sm">
              {{ mention.bricks.note }}
            </div>
            <div
              class="flex justify-between items-center text-xs text-panel-secondary-text"
            >
              <div>
                {{ intShortDateTime(mention.bricks.insertedAt, locale) }}
              </div>
              <div v-if="false" class="flex">
                <SvgIcon name="globe-alt" class="w-4 h-4 mr-0.5" />
                World
              </div>
              <div v-if="false" class="flex">
                <SvgIcon name="heart" class="w-5 h-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- details -->
    <div class="grid grid-cols-1 px-3 divide-y divide-panel-hr-border">
      <!-- URL -->
      <DetailSegmentUrl
        v-if="urlDetails.length"
        :details="urlDetails"
        :item="selectedItem"
      />
      <!-- Phone -->
      <DetailSegmentPhone
        v-if="phoneDetails.length"
        :details="phoneDetails"
        :item="selectedItem"
      />
      <!-- Email -->
      <DetailSegmentEmail
        v-if="isQuoPro && emailDetails.length"
        :details="emailDetails"
        :item="selectedItem"
      />
      <!-- Hours -->
      <DetailSegmentOpeningHours
        v-if="hoursDetails.length"
        :details="hoursDetails"
        :item="selectedItem"
      />
      <!-- Eating & Drinking -->
      <DetailSegmentEating v-if="hasEatingContent" :item="selectedItem" />
      <!-- Booking -->
      <DetailSegmentBooking v-if="hasBookingContent" :item="selectedItem" />
      <!-- Meta Information -->
      <DetailSegmentMetaInformation
        v-if="metaInformationDetails.length"
        :details="metaInformationDetails"
        :item="selectedItem"
      />
      <!-- Address -->
      <DetailSegmentAddress
        v-if="address && address.length"
        :address="address"
      />
      <!-- Social -->
      <DetailSegmentSocial
        v-if="socialDetails.length"
        :details="socialDetails"
        :item="selectedItem"
        :name="itemTitle"
      />
    </div>
    <!-- Footer -->
    <div class="px-3 py-10">
      <div class="text-sm text-center text-slate-400">
        &copy; Quo Space<span
          v-if="user && user.user_metadata && user.user_metadata.dashboard"
        >
          <NuxtLink
            :to="
              localePath({
                name: 'dashboard-places-id',
                params: { id: selectedItem.id },
              })
            "
          >
            &middot; edit</NuxtLink
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useGesture } from '@vueuse/gesture'
// import { useMotionProperties, useSpring } from '@vueuse/motion'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useShare,
  useWindowSize,
} from '@vueuse/core'
import { vScroll } from '@vueuse/components'
import {
  isDate,
  isPast,
  isToday,
  isTomorrow,
  isYesterday,
  parseISO,
} from 'date-fns'

const { $log, $metric } = useNuxtApp()
$log('components:ItemDetail:setup:begin')

onMounted(() => {
  $log('components: ItemDetail: onMounted')
  $log('components: ItemDetail: detailSheetPosition', detailSheetPosition.value)
  if (detailSheetPosition.value === 0) {
    controllerStore.setDetailSheetPosition(snapMiddlePosition.value)
  }
  height.value = windowHeight.value - detailSheetPosition.value
})

onUpdated(() => {
  $log('components: ItemDetail: onUpdated')
})

onUnmounted(() => {
  $log('components: ItemDetail: onUnmounted')
})

const { locale, t } = useI18n()
const localePath = useLocalePath()

const { intShortDateTime } = useDateTime()

const profileStore = useProfileStore()
const { isAuthenticated, isQuoPro } = storeToRefs(profileStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const itemStore = useItemStore()
const { selectedItem } = storeToRefs(itemStore)

const listStore = useListStore()
const { selectedListId } = storeToRefs(listStore)

const feedStore = useFeedStore()
const { showFeedResult } = storeToRefs(feedStore)

const controllerStore = useControllerStore()
const {
  detailSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const searchStore = useSearchStore()
const { searchText, selectedHighlightId } = storeToRefs(searchStore)

const user = useSupabaseUser()

const localeRoute = useLocaleRoute()

const { fullPath } = localeRoute({
  name: 'map-place-nanoId-slug',
  params: { nanoId: selectedItem.value.id },
})

const { closeSelectedItem } = useController()

const {
  getItemNewCategorySuggestion,
  getItemShortDescription,
  getItemTitle,
  getMediumUrl,
} = useItem()
const itemTitle = computed(() => getItemTitle(selectedItem.value, locale.value))
const itemShortDescription = computed(() =>
  getItemShortDescription(selectedItem.value, locale.value)
)

const newCategorySuggestion = computed(() =>
  getItemNewCategorySuggestion(selectedItem.value, locale.value)
)

const mediumUrl = computed(() => {
  const url = getMediumUrl(selectedItem.value.media[0])
  if (url !== null) return url
  if (
    selectedItem.value.legacy_media !== undefined &&
    selectedItem.value.legacy_media.length > 0
  ) {
    return selectedItem.value.legacy_media[0].url
  }
  return null
})
$log('components: ItemDetail: mediumUrl', mediumUrl)

const { getItemSubTitleTags, hasSegmentTagsBySegmentKey } = useTag()
const subTitleTags = computed(() =>
  getItemSubTitleTags(selectedItem.value, locale.value)
)
$log('components: ItemDetail: getItemSubTitleTags', subTitleTags)

const { getOpenClosedOrChangeShort } = useOpeningHours()
const openClosedOrChangeShort = computed(() =>
  getOpenClosedOrChangeShort(selectedItem.value, 'hours')
)
$log('components: ItemDetail: openClosedOrChangeShort', openClosedOrChangeShort)

const checkinStore = useCheckinStore()

const createCheckin = async () => {
  $log('components: ItemDetail: createCheckin')
  if (!isQuoPro.value) return
  await checkinStore.addCheckin(selectedItem.value.id)
}

const clickCheckin = () => {
  if (isQuoPro.value) {
    createCheckin()
    return
  }
  if (isAuthenticated.value) {
    return navigateTo(
      localePath({
        name: 'auth-checkout-choose-product',
        query: { chosenproduct: 'quo-pro', redirect: fullPath },
      })
    )
  } else {
    return navigateTo(
      localePath({
        name: 'auth-sign-in',
        query: { redirect: fullPath },
      })
    )
  }
}

const checkinsCount = computed(() => {
  const checkinsCount = checkinStore.checkinsCount.filter(
    (checkin) => checkin.itemId === selectedItem.value.id
  )
  if (checkinsCount.length > 0) {
    return checkinsCount[0].count
  } else {
    return 0
  }
})

const likeStore = useLikeStore()
const likeState = computed(() =>
  likeStore.getLikeStateByItemId(selectedItem.value.id)
)

const likesCount = computed(() => {
  const likesCount = likeStore.likesCount.filter(
    (like) => like.itemId === selectedItem.value.id
  )
  if (likesCount.length > 0) {
    return likesCount[0].count
  } else {
    return 0
  }
})

$log('components: ItemDetail: likeState', likeState.value)

const toggleLike = async () => {
  $log('components: ItemDetail: toggleLike')
  if (!isQuoPro.value) return
  await likeStore.toggleLike(selectedItem.value.id)
}

const clickToggleLike = async () => {
  if (isQuoPro.value) {
    await toggleLike()
    return
  }
  if (isAuthenticated.value) {
    return navigateTo(
      localePath({
        name: 'auth-checkout-choose-product',
        query: { chosenproduct: 'quo-pro', redirect: fullPath },
      })
    )
  } else {
    return navigateTo(
      localePath({
        name: 'auth-sign-in',
        query: { redirect: fullPath },
      })
    )
  }
}

const stats = computed(() => {
  const newStats = []
  newStats.push({
    name: t('detailSheet.checkins', checkinsCount.value),
    count: checkinsCount.value,
  })
  newStats.push({
    name: t('detailSheet.likes', likesCount.value),
    count: likesCount.value,
  })
  return newStats
})

const nonZeroStats = computed(() => {
  return stats.value.filter((stat) => stat.count > 0)
})

const mentionStore = useMentionStore()

const clickMention = () => {
  if (isQuoPro.value) {
    controllerStore.openBottomSheetMention()
    return
  }
  if (isAuthenticated.value) {
    return navigateTo(
      localePath({
        name: 'auth-checkout-choose-product',
        query: { chosenproduct: 'quo-pro', redirect: fullPath },
      })
    )
  } else {
    return navigateTo(
      localePath({
        name: 'auth-sign-in',
        query: { redirect: fullPath },
      })
    )
  }
}

const { getAvatarDownloadLink } = useProfile()

const profileAvatarUrl = async (userId) => {
  const profile = profileStore.getProfileByUserId(userId)
  const url = await getAvatarDownloadLink(profile.avatarUrl)
  return url
}

const mentions = computed(() => {
  const newMentions = mentionStore.getMentionsByItemId(selectedItem.value.id)
  if (newMentions.length > 0) {
    newMentions.sort((a, b) => {
      return new Date(b.insertedAt) - new Date(a.insertedAt)
    })
    newMentions.forEach(async (mention) => {
      const profile = profileStore.getProfileByUserId(mention.userId)
      mention.profile = profile
      mention.profile.fullAvatarUrl = await profileAvatarUrl(mention.userId)
      return mention
    })
    return newMentions
  } else {
    return []
  }
})

$log('components: ItemDetail: mentions', mentions.value)

const urlDetails = computed(() => {
  const urlDetails = []
  selectedItem.value.details.forEach((detail) => {
    if (detail.key === 'url') {
      urlDetails.push(detail)
    }
  })
  return urlDetails
})

const phoneDetails = computed(() => {
  const phoneDetails = []
  selectedItem.value.details.forEach((detail) => {
    if (detail.key === 'phone') {
      phoneDetails.push(detail)
    }
  })
  return phoneDetails
})

const emailDetails = computed(() => {
  const emailDetails = []
  selectedItem.value.details.forEach((detail) => {
    if (detail.key === 'email') {
      emailDetails.push(detail)
    }
  })
  return emailDetails
})

const hoursDetails = computed(() => {
  const hoursDetails = []
  selectedItem.value.details.forEach((detail) => {
    if (detail.key === 'hours') {
      hoursDetails.push(detail)
    }
  })
  return hoursDetails
})

const metaInformationDetails = computed(() => {
  const metaInformationDetails = []
  selectedItem.value.details.forEach((detail) => {
    if (
      detail.key === 'capacity' ||
      detail.key === 'elevationM' ||
      detail.key === 'established' ||
      detail.key === 'heightM' ||
      detail.key === 'startDate'
    ) {
      metaInformationDetails.push(detail)
    }
  })
  return metaInformationDetails
})

const address = computed(() => {
  if (selectedItem.value.addresses && selectedItem.value.addresses.length > 0) {
    return selectedItem.value.addresses
  } else {
    return null
  }
})

const { share, isSupported } = useShare()

const { currentRoute } = useRouter()

const { formatAddress } = useAddressRecipe()
const formattedAddress = formatAddress(address)

$log('components: ItemDetail: formattedAddress', formattedAddress)
$log('components: ItemDetail: currentRoute', currentRoute.value)

// TODO: refer to dynamic URL
const shareObject = {
  title: itemTitle.value,
  url: 'https://quo.space' + currentRoute.value.fullPath,
}

if (address.value !== null) {
  shareObject.text = formattedAddress
}

$log('components: ItemDetail: shareObject', shareObject)

const startShare = () => {
  share(shareObject)
}

const { getDetailsBySegmentKey, hasDetailsBeSegmentKey } = useDetails()

const socialDetails = computed(() => {
  const socialDetails = getDetailsBySegmentKey(
    selectedItem.value.details,
    'socialMedia'
  )
  $log('components: ItemDetail: socialDetails: ', socialDetails)
  if (socialDetails === null) return []
  return socialDetails
})

const { intDate } = useDateTime()

const permanentlyClosedText = computed(() => {
  if (selectedItem.value.permanentlyClosedAt !== null) {
    const dateClosed = parseISO(selectedItem.value.permanentlyClosedAt)
    if (isDate(dateClosed)) {
      if (isToday(dateClosed)) {
        return t('permanentlyClosed.today')
      } else if (isYesterday(dateClosed)) {
        return t('permanentlyClosed.yesterday')
      } else if (isPast(dateClosed)) {
        return t('permanentlyClosed.past', {
          formattedDateClosed: intDate(dateClosed, locale.value),
        })
      } else if (isTomorrow(dateClosed)) {
        return t('permanentlyClosed.tomorrow')
      } else {
        return t('permanentlyClosed.future', {
          formattedDateClosed: intDate(dateClosed, locale.value),
        })
      }
    }
  }
  return null
})

const hasEatingContent = computed(() => {
  if (
    hasSegmentTagsBySegmentKey(selectedItem.value.tags, 'eatingDrinking') ||
    hasSegmentTagsBySegmentKey(
      selectedItem.value.tags,
      'eatingDrinkingReserve'
    ) ||
    hasDetailsBeSegmentKey(selectedItem.value.details, 'eatingDrinking') ||
    hasDetailsBeSegmentKey(selectedItem.value.details, 'eatingDrinkingReserve')
  ) {
    return true
  } else {
    return false
  }
})

const hasBookingContent = computed(() => {
  if (hasDetailsBeSegmentKey(selectedItem.value.details, 'booking')) {
    return true
  } else {
    return false
  }
})

const detailMoveHandle = ref()
const height = ref(0)
const detailSheet = ref()
const showTitleOverImage = ref(false)

const { height: windowHeight } = useWindowSize()

// const { motionProperties } = useMotionProperties(detailMoveHandle, {
//   cursor: 'grab',
//   x: 0,
//   y: 0,
// })
// onMounted(() => {
//   console.log('mounted')
//   height.value = '50vh'
// })
// Bind the motion properties to a spring reactive object.
// const { set } = useSpring(motionProperties)
// const detailMoveHandle = ({ movement: [x, y], dragging, tap }) => {
//   console.log(dragging, x, useRound(y).value, tap)
//   isLocked.value = dragging
//   if (!dragging) {
//     set({ x: 0, y: 0, cursor: 'grab' })
//     return
//   }
//   height.value = useRound(y).value
//   set({ cursor: 'grabbing', x: 0, y })
// }

// useDrag(moveHandler, { domTarget: moveHandle, useTouch: true })

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    scrollY: y,
  }
  controllerStore.addDevInfo(newDevInfo)
  if (y.value > 28) {
    showTitleOverImage.value = true
  } else {
    showTitleOverImage.value = false
  }
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: detailMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

const isDragging = ref(false)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe }) {
  height.value =
    windowHeight.value - detailSheetPosition.value - useRound(y).value
  isDragging.value = dragging
  // const offset = sheet.value.getBoundingClientRect()
  const close = findClosestSnapPoint(
    detailSheetPosition.value + useRound(y).value
  )
  // console.log(
  //   `Is dragging: ${dragging}, diff y: ${
  //     useRound(y).value
  //   }, tap: ${tap}, swipe: ${swipe}, absolute y: ${
  //     offset.top
  //   }, snapMiddlePosition: ${snapMiddlePosition.value}, height: ${
  //     height.value
  //   }, close: ${close}`
  // )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      detailSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next
  }
  if (!dragging) {
    controllerStore.setDetailSheetPosition(close)
    height.value = windowHeight.value - close
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

const close = async () => {
  if (searchText) {
    searchText.value = null
  }
  await closeSelectedItem()
}

watch(
  selectedItem,
  async () => {
    if (selectedItem.value && import.meta.client) {
      $log('components: ItemDetail: selectedItem changed')
      await $metric({ key: 'PAGE_VIEW', itemId: selectedItem.value.id })
    }
    await checkinStore.fetchCheckinsCount(selectedItem.value.id)
    await likeStore.fetchLikesCount(selectedItem.value.id)
  },
  { immediate: true }
)

$log('components:ItemDetail:setup:end')
</script>
