<template>
  <div class="fixed z-40 h-screen w-full top-0 bg-panel-background-behind/70">
    <div
      class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto"
    >
      <div class="flex flex-col">
        <div class="flex justify-between items-center border-b py-3 px-4">
          <div class="w-6">&nbsp;</div>
          <div class="text-xl font-medium text-panel-accent-text">
            {{ $t('bottomSheetListCreate.title') }}
          </div>
          <div
            class="cursor-pointer"
            @click="controllerStore.closeBottomSheetListCreate"
          >
            <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
          </div>
        </div>
        <Form class="mx-auto space-y-4 p-4" @submit="onSubmit">
          <div class="flex flex-col">
            <div class="ml-4 mb-1 text-panel-primary-text text-sm">Name</div>
            <div
              class="relative flex items-center bg-panel-list-pill-background w-80 rounded-full"
            >
              <Field
                name="name"
                type="text"
                class="ml-4 !outline-none text-md font-medium leading-6 py-2 text-panel-list-text bg-transparent w-full border-transparent focus:border-transparent focus:ring-0 px-0 text-md"
                placeholder="Italy trip with friends 🇮🇹"
                autocomplete="off"
                data-1p-ignore
                :rules="validateListName"
              />
            </div>
            <div class="ml-4 mt-1 text-panel-alert-message text-sm">
              <ErrorMessage name="name" />
            </div>
            <AlertBox
              v-if="apiError"
              theme="error"
              :message="apiError.message"
              class="mt-2"
            />
          </div>
          <button
            class="flex justify-center w-full rounded-full bg-panel-action text-white py-2 cursor-pointer"
          >
            {{ $t('bottomSheetListCreate.createListCTA') }}
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
const { $log } = useNuxtApp()
const controllerStore = useControllerStore()
const { listForItemId } = storeToRefs(controllerStore)

const itemStore = useItemStore()
const createListForItem = computed(() => {
  if (listForItemId.value === null) return null
  itemStore.getItemById(listForItemId.value)
})

const profileStore = useProfileStore()
const { isQuoPro } = storeToRefs(profileStore)

const listStore = useListStore()

const { t } = useI18n()

const loading = ref(false)
const apiError = ref(null)

const handleCreateList = async (values) => {
  const iconStore = useIconStore()
  const icon = iconStore.getIconByName('list-bullet')
  $log(
    'components/BottomSheetListCreate.vue: handleCreateList: icon: ',
    icon.id
  )
  const data = await listStore.createList(values.name, 'list', icon.id)
  $log('components/BottomSheetListCreate.vue: handleCreateList: data: ', data)
  $log(
    'components/BottomSheetListCreate.vue: handleCreateList: createListForItem: ',
    createListForItem.value
  )
  if (data && data.id) {
    if (createListForItem.value) {
      listStore.toggleListsItem(createListForItem.value.id, data.id)
    }
    controllerStore.closeBottomSheetListCreate()
    controllerStore.closeBottomSheetList()
  }
}

const onSubmit = async (values) => {
  $log('submit: values: ', values)
  if (!isQuoPro) return
  loading.value = true
  await handleCreateList(values)
  loading.value = false
}

const validateListName = (value) => {
  $log('validateListName: value: ', value)
  if (!(value && value.trim())) {
    return t('bottomSheetListCreate.errors.nameRequired')
  }
  if (value && value.length > 64) {
    return t('bottomSheetListCreate.errors.nameTooLong')
  }
  return true
}
</script>
