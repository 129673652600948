export const useFeedStore = defineStore('feed', {
  state: () => ({
    featured: [],
    showFeedResult: false,
  }),
  actions: {
    async fetchFeatured() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('featured')
        .select('id, featuredAt:featured_at, itemId:item_id')
        .order('featured_at', { ascending: false })
      console.log('store:feed: fetchFeatured: data: ', data.length)
      if (data) {
        this.featured = data
      }
    },
    setShowFeedResult(showFeedResult) {
      this.showFeedResult = showFeedResult
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFeedStore, import.meta.hot))
}
