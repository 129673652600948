<template>
  <div
    class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto"
  >
    <div class="flex flex-col">
      <div class="flex justify-between items-center border-b py-3 px-4">
        <div class="w-6">&nbsp;</div>
        <div class="text-xl font-medium text-panel-accent-text">
          {{ $t('bottomSheetPlaceCreate.title') }}
        </div>
        <div
          class="cursor-pointer"
          @click="controllerStore.closeBottomSheetPlaceCreate(false)"
        >
          <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
        </div>
      </div>
      <div
        class="flex flex-col mx-auto space-y-4 w-80 font-normal text-sm text-panel-accent-text px-4 pt-4 pb-6"
      >
        <span>{{ $t('bottomSheetPlaceCreate.intro') }}</span>
        <div
          class="flex flex-row bg-panel-list-pill-background rounded-lg p-3 cursor-pointer"
          @click="goToBottomSheetPlaceCreateQuo('quoMap')"
        >
          <div class="flex items-center justify-center">
            <SvgIcon
              name="map-pin"
              class="w-6 h-6 fill-pink-600 mr-2"
              type="solid"
            />
          </div>
          <div
            class="flex-auto flex items-center justify-start text-panel-list-pill-text font-semibold"
          >
            {{ $t('bottomSheetPlaceCreate.selectOnMap') }}
          </div>
          <div class="flex items-center justify-center">
            <SvgIcon
              name="chevron-right"
              class="w-6 h-6 fill-panel-list-pill-text ml-2"
              type="solid"
            />
          </div>
        </div>
        <div
          class="flex flex-row bg-panel-list-pill-background rounded-lg p-3 cursor-pointer"
          @click="goToBottomSheetPlaceCreateQuo('pasteGpsCoordinates')"
        >
          <div class="flex items-center justify-center">
            <SvgIcon
              name="clipboard-document"
              class="w-6 h-6 fill-panel-list-pill-text mr-2"
              type="solid"
            />
          </div>
          <div
            class="flex-auto flex items-center justify-start text-panel-list-pill-text font-semibold"
          >
            {{ $t('bottomSheetPlaceCreate.pasteGps') }}
          </div>
          <div class="flex items-center justify-center">
            <SvgIcon
              name="chevron-right"
              class="w-6 h-6 fill-panel-list-pill-text ml-2"
              type="solid"
            />
          </div>
        </div>
        <div
          class="flex flex-row bg-panel-list-pill-background rounded-lg p-3 cursor-pointer"
          @click="goToBottomSheetPlaceCreateQuo('pasteGoogleMapsLink')"
        >
          <div class="flex items-center justify-center">
            <SvgIcon
              name="google-maps"
              class="w-6 h-6 fill-brand-googlem-maps mr-2"
              type="solid"
            />
          </div>
          <div
            class="flex-auto flex items-center justify-start text-panel-list-pill-text font-semibold"
          >
            {{ $t('bottomSheetPlaceCreate.pasteGoogleMaps') }}
          </div>
          <div class="flex items-center justify-center">
            <SvgIcon
              name="chevron-right"
              class="w-6 h-6 fill-panel-list-pill-text ml-2"
              type="solid"
            />
          </div>
        </div>
        <AlertBox
          theme="warning"
          :message="$t('bottomSheetPlaceCreate.information')"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
const controllerStore = useControllerStore()

const goToBottomSheetPlaceCreateQuo = (method) => {
  controllerStore.setNewPlaceChosenMethod(method)
  controllerStore.openBottomSheetPlaceCreateQuo()
  controllerStore.closeBottomSheetPlaceCreate(true)
}
</script>
