<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="fork-knife" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start pt-2 w-full text-panel-primary-text"
    >
      <div class="grid grid-cols-1 divide-y w-full divide-panel-hr-border">
        <div v-if="eatingNotes.length" class="flex flex-row pt-2 pb-2">
          <div
            v-for="(eatingNote, eatingNoteIndex) in eatingNotes"
            :key="eatingNoteIndex"
          >
            {{ eatingNote.value }}
          </div>
        </div>
        <div v-if="cuisineTags.length" class="flex flex-row py-2">
          <div class="w-28 flex-shrink-0">
            {{ $t('eatingDrinking.cuisine') }}
          </div>
          <div class="whitespace-pre-line">
            <span v-for="(tag, index) in cuisineTags" :key="index">
              {{ tag.short }}
              {{ index < cuisineTags.length - 1 ? ' · ' : '' }}
            </span>
          </div>
        </div>
        <div v-if="dishTags.length" class="flex flex-row py-2">
          <div class="w-28 flex-shrink-0">
            {{ $t('eatingDrinking.dishes') }}
          </div>
          <div class="whitespace-pre-line">
            <span v-for="(tag, index) in dishTags" :key="index">
              {{ tag.short }}
              {{ index < dishTags.length - 1 ? ' · ' : '' }}
            </span>
          </div>
        </div>
        <div v-if="drinkTags.length" class="flex flex-row py-2">
          <div class="w-28 flex-shrink-0">
            {{ $t('eatingDrinking.drinks') }}
          </div>
          <div class="whitespace-pre-line">
            <span v-for="(tag, index) in drinkTags" :key="index">
              {{ tag.short }}
              {{ index < drinkTags.length - 1 ? ' · ' : '' }}
            </span>
          </div>
        </div>
        <div v-if="amenityTags.length" class="flex flex-row py-2">
          <div class="w-28 flex-shrink-0">
            {{ $t('eatingDrinking.amenities') }}
          </div>
          <div class="whitespace-pre-line">
            <span v-for="(tag, index) in amenityTags" :key="index">
              {{ tag.short }}
              {{ index < amenityTags.length - 1 ? ' · ' : '' }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="menus && menus.length > 0" class="flex flex-row py-1 w-full">
        <NuxtLink
          v-for="(menu, index) in menus"
          :key="index"
          class="flex flex-row my-1 py-2 bg-panel-list-pill-background w-full font-normal rounded-full"
          target="_blank"
          :to="menu.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: menu.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="document-text"
              class="w-5 h-5 ml-4 mr-2 fill-panel-primary-text"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto">
            {{ $t('eatingDrinking.menu') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-panel-primary-text stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
      </div>
      <div v-if="tableDirectly || opentable" class="flex flex-col py-1 w-full">
        <NuxtLink
          v-if="tableDirectly"
          class="flex flex-row my-1 py-2 bg-panel-list-pill-background w-full text-panel-primary-text font-normal rounded-full"
          target="_blank"
          :to="tableDirectly.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: tableDirectly.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="pencil-square"
              class="w-5 h-5 ml-4 mr-2 fill-panel-primary-textt"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto text-panel-primary-text">
            {{ $t('eatingDrinking.reserveTableDirectly') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-panel-primary-text stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
        <NuxtLink
          v-if="opentable"
          class="flex flex-row my-1 py-2 bg-panel-list-pill-background w-full text-panel-primary-text font-normal rounded-full"
          target="_blank"
          :to="opentable.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: opentable.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="opentable"
              class="w-5 h-5 ml-4 mr-2 fill-brand-opentable"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto">
            {{ $t('eatingDrinking.reserveOpentable') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-panel-primary-text stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const { locale } = useI18n()

const item = computed(() => props.item)

const { getDetailsByKey } = useDetails()
const { getTagsByKey } = useTag()

const eatingNotes = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const eatingNotesDetails = getDetailsByKey(
      item.value.details,
      'eatingNotes',
      locale
    )
    if (eatingNotesDetails && eatingNotesDetails.length > 0) {
      return eatingNotesDetails
    }
  }
  return []
})

const cuisineTags = computed(() => {
  if (item.value && item.value.tags && item.value.tags.length > 0) {
    const tags = getTagsByKey(item.value.tags, 'cuisine', locale)
    if (tags && tags.length > 0) {
      return tags
    }
  }
  return []
})

const dishTags = computed(() => {
  if (item.value && item.value.tags && item.value.tags.length > 0) {
    const tags = getTagsByKey(item.value.tags, 'dish', locale)
    if (tags && tags.length > 0) {
      return tags
    }
  }
  return []
})

const drinkTags = computed(() => {
  if (item.value && item.value.tags && item.value.tags.length > 0) {
    const tags = getTagsByKey(item.value.tags, 'drink', locale)
    if (tags && tags.length > 0) {
      return tags
    }
  }
  return []
})

const amenityTags = computed(() => {
  if (item.value && item.value.tags && item.value.tags.length > 0) {
    const tags = getTagsByKey(item.value.tags, 'eatingDrinkingAmenity', locale)
    if (tags && tags.length > 0) {
      return tags
    }
  }
  return []
})
const menus = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const menuDetails = getDetailsByKey(item.value.details, 'menu', locale)
    if (menuDetails && menuDetails.length > 0) {
      return menuDetails
    }
  }
  return []
})
const tableDirectly = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const tableDirectlyDetails = getDetailsByKey(
      item.value.details,
      'tableDirectly',
      locale
    )
    if (tableDirectlyDetails && tableDirectlyDetails.length > 0) {
      return tableDirectlyDetails[0]
    }
  }
  return null
})
const opentable = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const tableDirectlyDetails = getDetailsByKey(
      item.value.details,
      'opentable',
      locale
    )
    if (tableDirectlyDetails && tableDirectlyDetails.length > 0) {
      return tableDirectlyDetails[0]
    }
  }
  return null
})
</script>
