<template>
  <div>
    <NuxtLink
      is="button"
      v-for="(list, index) in shortcutsBarLists"
      :key="index"
      type="button"
      class="inline-flex items-center gap-x-1.5 rounded-lg bg-shortcut-background bg-opacity-80 backdrop-blur-sm text-sm text-shortcut-text ring-[.5px] ring-inset ring-shortcut-border py-1.5 px-2 whitespace-nowrap drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] mb-2 sm:mb-3 sm:my-4"
      active-class="!bg-shortcut-active-background !text-shortcut-active-text !ring-shortcut-active-border !bg-opacity-80 "
      :to="
        localePath({
          name: 'map-list-nanoId-slug',
          params: { nanoId: list.nanoId },
        })
      "
    >
      <SvgIcon :name="list.icons.name" class="h-5 w-5" />
      {{ getListTitle(list.name, list.purpose) }}
    </NuxtLink>
    <NuxtLink
      is="button"
      v-for="highlight in highlights"
      :key="highlight.id"
      type="button"
      class="inline-flex items-center gap-x-1.5 rounded-lg bg-shortcut-background bg-opacity-80 backdrop-blur-sm text-sm text-shortcut-text ring-[.5px] ring-inset ring-shortcut-border py-1.5 px-2 whitespace-nowrap drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] mb-2 sm:mb-3 sm:my-4 cursor-pointer"
      active-class="!bg-shortcut-active-background !text-shortcut-active-text !ring-shortcut-active-border !bg-opacity-80 "
      :to="
        localePath({
          name: 'map-highlight-highlightUrlName',
          params: { highlightUrlName: highlight.translation.urlName },
        })
      "
    >
      <SvgIcon
        :name="highlight.icon"
        :type="highlight.iconType"
        class="h-4 w-4"
      />
      {{ highlight.translation.name }}
    </NuxtLink>
  </div>
</template>
<script setup>
const { locale } = useI18n()
const listStore = useListStore()
const { shortcutsBarLists } = storeToRefs(listStore)
const { getListTitle, isListAvailable } = useList()
const localePath = useLocalePath()

const searchStore = useSearchStore()
const { getLocalHighlights } = storeToRefs(searchStore)

const highlights = computed(() => {
  return getLocalHighlights.value(locale.value)
})
</script>
