<template>
  <div
    class="flex flex-col mx-4 bg-panel-background rounded-xl pointer-events-auto h-[42vh] sm:h-full overflow-y-hidden"
  >
    <div class="flex border-b border-panel-hr-border">
      <div class="flex-grow">&nbsp;</div>
      <div class="">
        <div class="py-2 px-4">
          <button
            type="button"
            class="bg-panel-nav-control-background w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto"
            @click="searchText = null"
          >
            <SvgIcon
              name="x-mark"
              class="stroke-panel-nav-control-action-symbol h-5 w-5 my-auto mx-auto"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-y-auto">
      <NuxtLink
        v-for="(result, index) in fullSearchResult"
        :key="index"
        class="flex flex-row m-1 p-3 cursor-pointer border-b border-panel-hr-border"
        :to="createRoute(result)"
      >
        <div class="flex-none mr-3">
          <div class="px-1 pt-0.5 rounded-full">
            <SvgIcon
              :name="getItemIcon(result, locale).name"
              type="solid"
              class="h-5 w-5 fill-panel-accent-text"
            />
          </div>
        </div>
        <div class="grow flex flex-col min-w-0 space-y-1">
          <div class="text-md font-bold text-panel-accent-text">
            {{ getItemTitle(result, locale) }}
          </div>
          <div class="text-sm text-panel-secondary-text">
            <span
              v-for="(tag, tagIndex) in getItemSubTitleTags(result, locale)"
              :key="tagIndex"
            >
              <span v-if="tagIndex > 0"> &middot; </span>
              {{ tag.short }}
            </span>
            <span v-if="result.address && result.address.city">
              &middot; {{ result.address.city }}</span
            >
            <span v-if="result.address && result.address.country">
              &middot; {{ result.address.country }}</span
            >
          </div>
        </div>
      </NuxtLink>
      <div v-if="searchResult.length === 0" class="p-4">Nothing found</div>
    </div>
  </div>
</template>
<script setup>
const { $log } = useNuxtApp()
const { locale, t } = useI18n()

const searchStore = useSearchStore()
const { searchText } = storeToRefs(searchStore)

const mapStore = useMapStore()
const { mapFullSwLngLat, mapFullNeLngLat } = storeToRefs(mapStore)

const client = useSupabaseClient()

const { getItemTitle } = useItem()
const { getItemIcon, getItemSubTitleTags } = useTag()

const { getItemResultAddress } = useAddress()

const localePath = useLocalePath()

const createRoute = (result) => {
  let route = {}
  route = localePath({
    name: 'map-place-nanoId-slug',
    params: { nanoId: result.nanoId },
  })
  return route
}

const { data: searchResult } = await useAsyncData(
  'searchText',
  async () => {
    const { data, error } = await client.rpc('search_by_box', {
      search_text: searchText.value,
      search_language: 'english',
      min_lon: mapFullSwLngLat.value.lng,
      min_lat: mapFullSwLngLat.value.lat,
      max_lon: mapFullNeLngLat.value.lng,
      max_lat: mapFullNeLngLat.value.lat,
    })
    if (error) {
      $log('components/QuickResult: error: ', error)
      return []
    }
    return data
  },
  {
    watch: [searchText, mapFullSwLngLat.value, mapFullNeLngLat.value],
  }
)

const fullSearchResult = computed(() => {
  // lookup address for each result
  const resultWithAddress = searchResult.value.map((result) => {
    const address = getItemResultAddress(result, locale)
    return { ...result, address }
  })
  return resultWithAddress
})
</script>
