<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon
          name="bed-king"
          type="outline"
          class="w-6 h-6 stroke-slate-500"
        />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start pt-2 w-full text-panel-primary-text"
    >
      <div
        v-if="directly || booking || airbnb"
        class="flex flex-col py-1 w-full"
      >
        <NuxtLink
          v-if="directly"
          class="flex flex-row my-1 py-2 bg-panel-list-pill-background w-full text-panel-primary-text font-normal rounded-full"
          target="_blank"
          :to="directly.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: directly.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="calendar"
              class="w-5 h-5 ml-4 mr-2 fill-panel-primary-textt"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto text-panel-primary-text">
            {{ $t('booking.bookDirectly') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-panel-primary-text stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
        <NuxtLink
          v-if="booking"
          class="flex flex-row my-1 py-2 bg-brand-booking w-full text-white font-normal rounded-full"
          target="_blank"
          :to="booking.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: booking.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="booking-com"
              class="w-5 h-5 ml-4 mr-2 fill-white"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto">
            {{ $t('booking.bookOnBookingCom') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-white stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
        <NuxtLink
          v-if="airbnb"
          class="flex flex-row my-1 py-2 bg-brand-airbnb w-full text-white font-normal rounded-full"
          target="_blank"
          :to="airbnb.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: airbnb.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              name="airbnb"
              class="w-5 h-5 ml-4 mr-2 fill-white"
              type="solid"
            />
          </div>
          <div class="flex items-center flex-auto">
            {{ $t('booking.bookOnAirbnb') }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-white stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const { locale } = useI18n()

const item = computed(() => props.item)

const { getDetailsByKey } = useDetails()

const directly = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const directlyDetails = getDetailsByKey(
      item.value.details,
      'directly',
      locale
    )
    if (directlyDetails && directlyDetails.length > 0) {
      return directlyDetails[0]
    }
  }
  return null
})
const booking = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const bookingDetails = getDetailsByKey(
      item.value.details,
      'booking',
      locale
    )
    if (bookingDetails && bookingDetails.length > 0) {
      return bookingDetails[0]
    }
  }
  return null
})
const airbnb = computed(() => {
  if (item.value && item.value.details && item.value.details.length > 0) {
    const airbnbDetails = getDetailsByKey(item.value.details, 'airbnb', locale)
    if (airbnbDetails && airbnbDetails.length > 0) {
      return airbnbDetails[0]
    }
  }
  return null
})
</script>
