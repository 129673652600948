<template>
  <div>
    <div id="map" class="mapboxgl-default-map-size select-none" />
    <ClientOnly v-if="showDebugInfo">
      <dl
        class="fixed right-20 left-20 top-safe-4 sm:top-safe-24 divide-y divide-gray-200 bg-gray-300/50"
      >
        <div
          v-for="entry in Object.entries(devInfo)"
          :key="entry[0]"
          class="flex justify-between py-1 px-1 text-xs"
        >
          <dt class="text-gray-500 mr-1">{{ entry[0] }}</dt>
          <dd class="text-gray-900">{{ entry[1] }}</dd>
        </div>
        <div class="flex justify-between py-1 px-1 text-xs">
          <dt class="text-gray-500 mr-1">mapError</dt>
          <dd class="text-gray-900">{{ mapError }}</dd>
        </div>
        <div class="flex gap-3">
          <button
            class="text-xs bg-gray-500 text-white p-1 rounded-md"
            @click="
              map.flyTo({
                center: [-80.80661474527504, 28.614312108657245],
                zoom: 17,
              })
            "
          >
            FlyTo Titusville
          </button>
          <button
            class="text-xs bg-gray-500 text-white p-1 rounded-md"
            @click="mapReconstruct()"
          >
            Reconstruct
          </button>
        </div>
      </dl>
    </ClientOnly>
    <ClientOnly>
      <div
        class="flex flex-col fixed right-4 top-60 space-y-4 items-end select-none"
      >
        <div
          class="flex items-center justify-center bg-map-control-background bg-opacity-80 drop-shadow backdrop-blur-sm w-10 h-10 rounded-lg cursor-pointer"
          @click="straightenMap()"
        >
          <SvgIcon
            name="navigation"
            type="solid"
            class="h-6 w-6 fill-map-control-icon"
            :style="'transform: rotate(' + bearing * -1 + 'deg)'"
          />
        </div>
        <div
          class="flex items-center justify-center bg-map-control-background bg-opacity-80 drop-shadow backdrop-blur-sm w-10 h-10 rounded-lg cursor-pointer"
          @click="map._controls[1].trigger()"
        >
          <SvgIcon
            :name="
              geolocationState.iconName === null
                ? 'geolocation-dot'
                : geolocationState.iconName
            "
            type="solid"
            class="h-6 w-6"
            :class="[
              geolocationState.color === null
                ? 'fill-map-control-icon'
                : 'fill-' + geolocationState.color,
              geolocationState.animate ? 'animate-spin-slow' : '',
            ]"
          />
        </div>
        <div
          class="flex items-center justify-center bg-map-control-background bg-opacity-80 drop-shadow backdrop-blur-sm w-10 h-10 rounded-lg cursor-pointer"
          @click="controllerStore.openBottomSheetMapStyle"
        >
          <SvgIcon
            name="map"
            type="solid"
            class="h-5 w-5 fill-map-control-icon"
          />
        </div>
        <div
          v-if="isQuoPro"
          class="flex items-center justify-center bg-emerald-500 bg-opacity-80 drop-shadow backdrop-blur-sm w-12 h-12 rounded-full cursor-pointer shadow -mr-0.5"
          @click="controllerStore.openBottomSheetPlaceCreate"
        >
          <SvgIcon name="plus" type="solid" class="h-6 w-6 fill-white" />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>
<script setup>
import { formatISO } from 'date-fns'
import mapboxgl from 'mapbox-gl'
import * as Sentry from '@sentry/browser'
import { render } from 'vue'
import {
  breakpointsTailwind,
  useArrayDifference,
  useDocumentVisibility,
  useBreakpoints,
  useWindowSize,
} from '@vueuse/core'
import { useRound } from '@vueuse/math'
import MapContextMenu from '@/components/MapContextMenu.vue'
import { mapSvgUrl } from '~/utils/mapSvgUrl'
import colors from '#tailwind-config/theme/colors'

const { $log } = useNuxtApp()

let map = null

let mapError = null
const documentVisibility = useDocumentVisibility()

const profileStore = useProfileStore()
const { isQuoPro } = storeToRefs(profileStore)

const mapStore = useMapStore()
const { reconstructMap, mapFullSwLngLat, mapFullNeLngLat, mapVisibleBounds } =
  storeToRefs(mapStore)

const mapPersistedStore = useMapPersistedStore()
const { selectedStyleId, center, zoom, pitch, bearing } =
  storeToRefs(mapPersistedStore)

const { addMouseEvents, initSourceAndLayer, setDataForSource } = useMap()

const appStore = useAppStore()
const { showDebugInfo } = storeToRefs(appStore)

const controllerStore = useControllerStore()
const {
  bottomViewOpen,
  detailSheetPosition,
  devInfo,
  feedResultSheetPosition,
  listResultSheetPosition,
  newPlaceLat,
  newPlaceLon,
  searchResultSheetPosition,
  sideViewOpen,
} = storeToRefs(controllerStore)

const { locale } = useI18n()
// const localePath = useLocalePath()

const itemStore = useItemStore()
const {
  highlightedItems,
  highlightedItemIds,
  items,
  mapItems,
  selectedItem,
  selectedItemId,
} = storeToRefs(itemStore)

const { getItemTitle, getItemMapSortKey } = useItem()

const listStore = useListStore()
const { selectedListId } = storeToRefs(listStore)

const tagStore = useTagStore()
const { getItemMainTag, getItemMapLayer } = useTag()

const likeStore = useLikeStore()
const { likes } = storeToRefs(likeStore)

const checkinStore = useCheckinStore()
const { checkins, latestCheckinByItemId } = storeToRefs(checkinStore)

const mentionStore = useMentionStore()
const { mentions, latestMentionByItemId } = storeToRefs(mentionStore)

const feedStore = useFeedStore()
const { featured, showFeedResult } = storeToRefs(feedStore)

const searchStore = useSearchStore()
const { selectedHighlightId } = storeToRefs(searchStore)

const { intShortDateTime } = useDateTime()

const colorMode = useColorMode()

const { height, width } = useWindowSize()

const styleLoaded = ref(false)

const highPrioItemsData = ref([])

const popup = ref()
const geolocationState = ref({
  state: null,
  iconName: null,
  color: null,
  animate: false,
})
const newPlaceMarker = ref()

const selectedStyle = computed(() => {
  return mapStore.getSelectedStyle(selectedStyleId.value)
})

const styleUrl = computed(() => {
  if (selectedStyle.value.useMapboxStandardStyle) {
    return null
  }
  if (colorMode.value === 'light') {
    return selectedStyle.value.light.styleUrl
  } else {
    return selectedStyle.value.dark.styleUrl
  }
})

const mapHaloColor = computed(() => {
  if (colorMode.value === 'light') {
    return selectedStyle.value.light.haloColor
  } else {
    return selectedStyle.value.dark.haloColor
  }
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const betweenSmAndLg = breakpoints.between('sm', 'lg')
const lgAndLarger = breakpoints.greaterOrEqual('lg')
const xs = breakpoints.smaller('sm')

const supabase = useSupabaseClient()

const styleIsMultiColor = computed(() => {
  return (
    selectedStyle.value.light.styleUrl !== selectedStyle.value.dark.styleUrl
  )
})

watch(
  () => colorMode.value,
  (newName, prevName) => {
    $log(
      'components:MapBase:watch:colorMode.value:from',
      prevName,
      'to',
      newName
    )
    if (selectedStyle.value.useMapboxStandardStyle) {
      $log(
        'components:MapBase:watch:colorMode.value:useMapboxStandardStyle:lightPreset',
        selectedStyle.value[colorMode.value].lightPreset
      )
      center.value = map.getCenter()
      zoom.value = map.getZoom()
      pitch.value = map.getPitch()
      bearing.value = map.getBearing()
      mapReconstruct()
    } else if (styleIsMultiColor.value) {
      // if styleUrl is the same and setStyle is called, map will not be refilled
      // as style.load is not triggered
      map.setStyle(styleUrl.value)
    }
  }
)

watch(
  () => selectedStyleId.value,
  (newName, prevName) => {
    $log(
      'components:MapBase:watch:selectedStyleId.value:from',
      prevName,
      'to',
      newName
    )
    if (selectedStyle.value.useMapboxStandardStyle) {
      $log('components:MapBase:watch:selectedStyleId.value:Standard')
      center.value = map.getCenter()
      zoom.value = map.getZoom()
      pitch.value = map.getPitch()
      bearing.value = map.getBearing()
      mapReconstruct()
    } else {
      map.setStyle(styleUrl.value)
    }
  }
)

const getViewHeight = () => {
  if (selectedItemId.value) {
    return detailSheetPosition.value
  } else if (selectedListId.value) {
    return listResultSheetPosition.value
  } else if (showFeedResult.value) {
    return feedResultSheetPosition.value
  } else if (selectedHighlightId.value) {
    return searchResultSheetPosition.value
  }
  return 0
}

const getPadding = () => {
  const defaultPadding = { left: 64, bottom: 96, right: 64, top: 96 }
  const newPadding = { ...defaultPadding }
  let mobileBottom = 96
  $log('components:MapBase:getPadding:newPadding:before', newPadding)
  $log('components:MapBase:getPadding:xs', xs.value)
  $log('components:MapBase:getPadding:betweenSmAndLg', betweenSmAndLg.value)
  $log('components:MapBase:getPadding:lgAndLarger', lgAndLarger.value)
  if (xs.value && (sideViewOpen.value || bottomViewOpen.value)) {
    let sideViewHeight = 0
    if (sideViewOpen.value) {
      sideViewHeight = height.value - getViewHeight() + 52
    }
    $log('components:MapBase:getPadding:sideViewHeight', sideViewHeight)
    const bottomViewHeight = height.value / 2
    $log('components:MapBase:getPadding:bottomViewHeight', bottomViewHeight)
    if (sideViewHeight > 0 && sideViewHeight > bottomViewHeight) {
      mobileBottom = sideViewHeight
    } else {
      mobileBottom = bottomViewHeight
    }
  }
  let desktopBottom = 0
  if (!xs.value && bottomViewOpen.value) {
    desktopBottom = height.value / 2
  }

  if (xs.value && mobileBottom > 0) {
    if (mobileBottom > 96) {
      newPadding.top = 24
      newPadding.bottom = mobileBottom
    } else {
      newPadding.top = 24
    }
  } else if (betweenSmAndLg.value) {
    if (sideViewOpen.value) {
      newPadding.left = width.value / 2
    }
    if (desktopBottom > 0) {
      newPadding.bottom = desktopBottom
    }
  } else if (lgAndLarger.value) {
    if (sideViewOpen.value) {
      newPadding.left = width.value / 3
    }
    if (desktopBottom > 0) {
      newPadding.bottom = desktopBottom
    }
  }
  $log('components:MapBase:getPadding:newPadding:after', newPadding)

  return newPadding
}

watch(
  () => selectedItemId.value,
  () => {
    $log('components:MapBase:watch:selectedItemId.value', selectedItemId)
    updateSources()
    if (selectedItemId.value && map) {
      const item = itemStore.getItemById(selectedItemId.value)
      $log('components:MapBase:watch:selectedItemId.value:item', item)
      if (!item) return
      const lngLat = [item.lon, item.lat]
      if (highlightedItemIds.value.length > 0) {
        // a list is open. move the map as little as possible. actually, only make sure that the selected item is within to bounds
        if (!mapVisibleBounds.value.contains(lngLat)) {
          map.easeTo({ center: lngLat, duration: 500 })
        }
        return
      }
      const padding = getPadding()
      $log('components:MapBase:watch:beforeEaseTo')
      map.easeTo({
        center: lngLat,
        padding,
        duration: 500,
      })
      $log('components:MapBase:watch:afterEaseTo')
    }
  }
)

watch(
  () => detailSheetPosition.value,
  () => {
    if (!map) return
    const padding = getPadding()
    map.easeTo({ padding, animate: false })
  }
)

watch(
  () => feedResultSheetPosition.value,
  () => {
    if (!map) return
    const padding = getPadding()
    map.easeTo({ padding, duration: 500 })
  }
)

watch(
  () => showFeedResult.value,
  () => {
    if (!map) return
    const padding = getPadding()
    map.easeTo({ padding, duration: 500 })
  }
)

watch(
  () => highlightedItemIds.value,
  (newVal, oldVal) => {
    if (!map) return
    if (newVal.length > 0) {
      // if (!map) return
      const diff = useArrayDifference(newVal, oldVal)
      $log(
        'components:MapBase:watch:highlightedItemIds:diff:newVal',
        newVal,
        'oldVal',
        oldVal
      )
      $log('components:MapBase:watch:highlightedItemIds:diff', diff.value)
      const diff2 = useArrayDifference(oldVal, newVal)
      $log('components:MapBase:watch:highlightedItemIds:diff2', diff2.value)
      if (diff.value.length > 0 || diff2.value.length > 0) {
        if (highlightedItemIds.value.length > 0 && !selectedItemId.value) {
          $log(
            'components:MapBase:watch:highlightedItemIds:highlightedItemIds',
            highlightedItemIds.value
          )
          $log(
            'components:MapBase:watch:highlightedItemIds:highlightedItemIds.length: ',
            highlightedItemIds.value.length
          )
          $log(
            'components:MapBase:watch:highlightedItemIds:highlightedItems',
            highlightedItems.value
          )
          if (highlightedItems.value.length > 1) {
            const padding = getPadding()
            // TODO: jumpTo and fitBounds must be far apart...
            map.jumpTo({ padding })
            if (!selectedHighlightId.value) {
              const bounds = new mapboxgl.LngLatBounds(
                [highlightedItems.value[0].lon, highlightedItems.value[0].lat],
                [highlightedItems.value[0].lon, highlightedItems.value[0].lat]
              )
              highlightedItems.value.forEach((item) => {
                bounds.extend([item.lon, item.lat])
              })
              $log(
                'components:MapBase:watch:highlightedItemIds:highlightedItemIds:bound',
                bounds
              )
              map.fitBounds(bounds, { linear: true })
            }
          } else if (highlightedItems.value.length === 1) {
            const padding = getPadding()
            if (!selectedHighlightId.value) {
              const item = highlightedItems.value[0]
              const lngLat = [item.lon, item.lat]
              $log('components:MapBase:watch:highlightedItemIds:lngLat', lngLat)
              map.easeTo({
                center: lngLat,
                padding,
                animate: false,
              })
            } else {
              map.jumpTo({ padding })
            }
          }
        }
        updateSources()
      }
    } else {
      $log(
        'components:MapBase:watch:highlightedItemIds:empty:oldVal',
        oldVal,
        'newVal',
        newVal
      )
      if (oldVal.length !== newVal.length) {
        updateSources()
      }
    }
  }
)

watch(
  () => listResultSheetPosition.value,
  () => {
    if (!map) return
    const padding = getPadding()
    map.easeTo({ padding, duration: 1000 })
  }
)

watch(
  () => searchResultSheetPosition.value,
  () => {
    if (!map) return
    const padding = getPadding()
    map.easeTo({ padding, duration: 1000 })
  }
)

watch(
  () => sideViewOpen.value,
  () => {
    $log('components:MapBase:watch:sideViewOpen', sideViewOpen.value)
    if (!map) return
    if (sideViewOpen.value === false) {
      const padding = getPadding()
      $log('components:MapBase:watch:sideViewOpen:padding', padding)
      map.easeTo({ padding, duration: 1000 })
    }
  }
)

watch(
  () => bottomViewOpen.value,
  () => {
    $log('components:MapBase:watch:bottomViewOpen', bottomViewOpen.value)
    // if (bottomViewOpen.value === false) {
    const padding = getPadding()
    map.easeTo({ padding, duration: 1000 })
    // }
  }
)

const mapItemIds = computed(() => {
  return items.value.map((item) => item.id)
})

// watch changes in array mapItemIds
watch(
  () => mapItemIds.value,
  (newVal, oldVal) => {
    $log(
      'components:MapBase:watch:mapItemIds: newVal.length: ',
      newVal.length,
      ' oldVal.length: ',
      oldVal.length
    )
    if (oldVal.length === 0) return
    const diff = useArrayDifference(newVal, oldVal)
    $log('components:MapBase:watch:mapItemIds:diff', diff.value)
    updateSources(diff.value)
  },
  { deep: true }
)

const onNewPlaceMarkerDragEnd = () => {
  const lngLat = newPlaceMarker.value.getLngLat()
  controllerStore.setNewPlaceLat(lngLat.lat)
  controllerStore.setNewPlaceLon(lngLat.lng)
  centerNewPlace()
}

const createNewPlaceMarker = () => {
  if (newPlaceMarker.value) {
    newPlaceMarker.value.remove()
  }
  newPlaceMarker.value = new mapboxgl.Marker({
    draggable: true,
    color: '#DB2777',
  })
    .setLngLat([newPlaceLon.value, newPlaceLat.value])
    .addTo(map)
    .on('dragend', onNewPlaceMarkerDragEnd)
  centerNewPlace()
}

const removeNewPlaceMarker = () => {
  if (newPlaceMarker.value) {
    newPlaceMarker.value.remove()
  }
}

const centerNewPlace = () => {
  const center = [newPlaceLon.value, newPlaceLat.value]
  const padding = getPadding()
  map.jumpTo({ center, padding, zoom: 18 })
}

watch(newPlaceLat, (newVal) => {
  if (newVal && newPlaceLon) {
    if (popup.value) {
      popup.value.remove()
    }
    createNewPlaceMarker()
  } else {
    removeNewPlaceMarker()
  }
})

watch(newPlaceLon, (newVal) => {
  if (newVal && newPlaceLat) {
    if (popup.value) {
      popup.value.remove()
    }
    createNewPlaceMarker()
  }
})

watch(
  showDebugInfo,
  (newVal) => {
    if (newVal === null || map === null) return
    map.showPadding = newVal
  },
  { immediate: true }
)

const getWatchState = () => {
  if (
    map &&
    map._controls &&
    map._controls.length > 0 &&
    map._controls[1] &&
    map._controls[1]._watchState
  ) {
    return map._controls[1]._watchState
  }
  return null
}

const checkWatchState = () => {
  const watchState = getWatchState()
  if (watchState !== null) {
    geolocationState.value.state = watchState
    switch (watchState) {
      case 'OFF':
        geolocationState.value.iconName = null
        geolocationState.value.color = null
        geolocationState.value.animate = false
        break
      case 'WAITING_ACTIVE':
        geolocationState.value.iconName = null
        geolocationState.value.color = 'sky-500'
        geolocationState.value.animate = true
        break
      case 'ACTIVE_LOCK':
        geolocationState.value.iconName = null
        geolocationState.value.color = 'sky-500'
        geolocationState.value.animate = false
        break
      case 'BACKGROUND':
        geolocationState.value.iconName = 'geolocation'
        geolocationState.value.color = 'sky-500'
        geolocationState.value.animate = false
        break
      case 'ACTIVE_ERROR':
        geolocationState.value.iconName = 'geolocation-slash'
        geolocationState.value.color = 'slate-300'
        geolocationState.value.animate = false
        break
      case 'BACKGROUND_ERROR':
        geolocationState.value.iconName = 'geolocation-slash'
        geolocationState.value.color = 'slate-300'
        geolocationState.value.animate = false
        break
      default:
        // Unexpected watchState
        geolocationState.value.iconName = 'geolocation-slash'
        geolocationState.value.color = 'slate-300'
        geolocationState.value.animate = false
        $log(
          'components:MapBase:CheckWatchState:' +
            watchState +
            ': Unexpected watchState'
        )
    }
  }
}

const likeTag = tagStore.getTagByKeyValue('symbolHelper', 'like')
const noTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')

const getFullItem = (lightItem) => {
  const item = { ...lightItem }
  let iconTagId = null
  let bgColor = null
  let subTitle = null
  let bucket = null

  if (item.likes > 0) {
    iconTagId = likeTag.id
    bgColor = likeTag.bgColor
  } else if (item.tags.length > 0) {
    iconTagId = item.tags[0].id
    bgColor = item.tags[0].bgColor
  } else if (noTag) {
    iconTagId = noTag.id
    bgColor = noTag.bgColor
  }

  if (item.likes > 0) {
    bucket = 'likes'
  } else if (item.mentions > 0) {
    bucket = 'mentions'
  } else if (item.checkins > 0) {
    bucket = 'checkins'
  } else if (item.listsItems > 0) {
    bucket = 'lists'
  } else if (item.featured > 0) {
    bucket = 'featured'
  }

  const textColorVar = colors.tag[bgColor]
  let textColor = ''
  if (textColorVar.startsWith('var(')) {
    const textColorVarName = textColorVar.replace('var(', '').replace(')', '')
    // $log('components:MapBase:getFullItem:textColorVarName', textColorVarName)
    textColor = getComputedStyle(document.documentElement).getPropertyValue(
      textColorVarName
    )
    // $log('components:MapBase:getFullItem:textColor:', textColor)
  } else if (textColorVar.startsWith('#')) {
    textColor = textColorVar
  }
  const tag = tagStore.getTagById(iconTagId)
  let mapLayer = 'low'
  if (tag) {
    mapLayer = tag.mapLayer
  }
  const newItem = {
    id: item.id,
    title: getItemTitle(item, locale),
    subtitle: subTitle,
    nanoId: item.nanoId,
    lat: item.lat,
    lon: item.lon,
    tagId: iconTagId,
    layer: mapLayer,
    permanentlyClosedAt: item.permanentlyClosedAt,
    hasSuccessor: item.hasSuccessor,
    iconImageDot: 'circle-dot-' + iconTagId,
    iconImageSymbol: 'circle-symbol-' + iconTagId,
    symbolSortKey: getItemMapSortKey(item),
    textColor,
    bucket,
  }
  return newItem
}

// create function that adds a passed array of items to mapItems
const mapItemsAdd = (newMapItems) => {
  $log('components:MapBase:mapItemsAdd:mapItems.value', mapItems.value)
  $log(
    'components:MapBase:mapItemsAdd:before:mapItems.value.length',
    mapItems.value.length
  )
  if (mapItems.value.length === 0) {
    $log('components:MapBase:mapItemsAdd:mapItems.length === 0')
    mapItems.value = newMapItems
    return
  }
  if (newMapItems.length > 0) {
    const mapItemsIds = mapItems.value.map((item) => item.id)
    // filter newMpaitems for items that are not in mapItems
    const filteredNewMapItems = newMapItems.filter(
      (item) => !mapItemsIds.includes(item.id)
    )

    $log(
      'components:MapBase:mapItemsAdd:filteredNewMapItems',
      filteredNewMapItems
    )
    if (filteredNewMapItems.length > 0) {
      filteredNewMapItems.forEach((item) => {
        const newItem = getFullItem(item)
        mapItems.value.push(newItem)
      })
    }
  }
  updateSources()
  $log(
    'components:MapBase:mapItemsAdd:after:mapItems.value.length',
    mapItems.value.length
  )
}

const seedMapItemsHighAltitude = () => {
  $log('components:MapBase:seedMapItemsHighAltitude:begin')
  if (mapItemsHighAltitudeData.value) {
    mapItemsHighAltitudeData.value.forEach((item) => {
      const newItem = getFullItem(item)
      mapItems.value.push(newItem)
    })
    $log('components:MapBase:seedMapItemsHighAltitude:mapItems', mapItems.value)
    // updateSources()
  }
  $log('components:MapBase:seedMapItemsHighAltitude:end')
}

const {
  data: mapItemsHighAltitudeData,
  pending: mapItemsHighAltitutdeDataLoading,
} = await useAsyncData('mapItemsHighAltitude', async () => {
  const { data } = await supabase.from('map_items_high_altitude').select()
  return data
})

const fetchMapItemsData = async () => {
  $log('components:MapBase:fetchMapItemsData:begin')

  if (map.getZoom() < 12) return
  if (
    typeof mapFullSwLngLat.value.lng !== 'number' ||
    typeof mapFullSwLngLat.value.lat !== 'number' ||
    typeof mapFullNeLngLat.value.lng !== 'number' ||
    typeof mapFullNeLngLat.value.lat !== 'number'
  ) {
    return
  }

  const { data } = await supabase.rpc('map_items_by_box', {
    min_lon: mapFullSwLngLat.value.lng,
    min_lat: mapFullSwLngLat.value.lat,
    max_lon: mapFullNeLngLat.value.lng,
    max_lat: mapFullNeLngLat.value.lat,
  })
  $log('components:MapBase:fetchMapItemsData:data', data)

  mapItemsAdd(data)

  $log('components:MapBase:fetchMapData:end')
}

function updateSources(itemIds = []) {
  $log('components:MapBase:updateSources:begin')
  if (!map) return
  // const engagedData = []
  const selectedItemMapItem = []

  const newEngagedItems = mapItems.value.filter((item) => {
    return (
      (item.bucket === 'likes' ||
        item.bucket === 'mentions' ||
        item.bucket === 'checkins' ||
        item.bucket === 'lists') &&
      !item.permanentlyClosedAt &&
      item.id !== selectedItemId.value &&
      !highlightedItemIds.value.includes(item.id)
    )
  })

  // const likeTag = tagStore.getTagByKeyValue('symbolHelper', 'like')
  // likes.value.forEach((like) => {
  //   const item = itemStore.getItemById(like.itemId)
  //   if (!item) return
  //   // if (isVisibleOnFullMap(item) === false) return
  //   const textColorVar = colors.tag[likeTag.bgColor]
  //   let textColor = ''
  //   if (textColorVar.startsWith('var(')) {
  //     const textColorVarName = textColor.replace(/var\((--[^)]+)\)/g, '$1')
  //     textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //       textColorVarName
  //     )
  //   } else if (textColorVar.startsWith('#')) {
  //     textColor = textColorVar
  //   }
  //   const newItem = {
  //     itemId: item.id,
  //     title: getItemTitle(item, locale),
  //     subtitle: null,
  //     nanoId: item.nanoId,
  //     lat: item.lat,
  //     lon: item.lon,
  //     tagId: likeTag.id,
  //     iconImageDot: 'circle-dot-' + likeTag.id,
  //     iconImageSymbol: 'circle-symbol-' + likeTag.id,
  //     symbolSortKey: getItemMapSortKey(item),
  //     textColor,
  //   }
  //   if (like.itemId === selectedItemId.value) {
  //     selectedItemMapItem.push(newItem)
  //   } else if (highlightedItemIds.value.includes(like.itemId)) {
  //     highlightedItemsMapItems.push(newItem)
  //   } else {
  //     engagedData.push(newItem)
  //   }
  // })
  // mentions.value.forEach((mention) => {
  //   const item = itemStore.getItemById(mention.itemId)
  //   if (!item) return
  //   // if (isVisibleOnFullMap(item) === false) return
  //   if (engagedData.find((engagedItem) => engagedItem.nanoId === item.nanoId))
  //     return
  //   const latestMention = latestMentionByItemId.value(item.id)
  //   if (!latestMention) return
  //   // check if latestMention has one or more bricks
  //   if (!latestMention.bricks) return
  //   let mentionText = latestMention.bricks.note
  //   if (!mentionText) return
  //   if (mentionText.length > 24) {
  //     mentionText = mentionText.substring(0, 24) + '...'
  //   }
  //   const mainTag = getItemMainTag(item, locale)
  //   let tagId = mainTag ? mainTag.id : 0
  //   if (tagId === 0) {
  //     const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //     if (newTag) {
  //       tagId = newTag.id
  //     }
  //   }
  //   const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //   let textColor = ''
  //   if (textColorVar.startsWith('var(')) {
  //     const textColorVarName = textColorVar.replace('var(', '').replace(')', '')
  //     textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //       textColorVarName
  //     )
  //   } else if (textColorVar.startsWith('#')) {
  //     textColor = textColorVar
  //   }
  //   const newItem = {
  //     itemId: item.id,
  //     title: getItemTitle(item, locale),
  //     subtitle: '\n' + mentionText,
  //     nanoId: item.nanoId,
  //     lat: item.lat,
  //     lon: item.lon,
  //     tagId,
  //     iconImageDot: 'circle-dot-' + tagId,
  //     iconImageSymbol: 'circle-symbol-' + tagId,
  //     symbolSortKey: getItemMapSortKey(item),
  //     textColor,
  //   }
  //   if (mention.itemId === selectedItemId.value) {
  //     selectedItemMapItem.push(newItem)
  //   } else {
  //     engagedData.push(newItem)
  //   }
  // })
  // checkins.value.forEach((checkin) => {
  //   const item = itemStore.getItemById(checkin.itemId)
  //   if (!item) return
  //   // if (isVisibleOnFullMap(item) === false) return
  //   if (engagedData.find((engagedItem) => engagedItem.nanoId === item.nanoId))
  //     return
  //   const latestCheckin = latestCheckinByItemId.value(item.id)
  //   if (!latestCheckin) return
  //   const mainTag = getItemMainTag(item, locale)
  //   let tagId = mainTag ? mainTag.id : 0
  //   if (tagId === 0) {
  //     const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //     if (newTag) {
  //       tagId = newTag.id
  //     }
  //   }
  //   const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //   let textColor = ''
  //   if (textColorVar.startsWith('var(')) {
  //     const textColorVarName = textColorVar.replace('var(', '').replace(')', '')
  //     textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //       textColorVarName
  //     )
  //   } else if (textColorVar.startsWith('#')) {
  //     textColor = textColorVar
  //   }
  //   const newItem = {
  //     itemId: item.id,
  //     title: getItemTitle(item, locale),
  //     subtitle: '\nLast: ' + intShortDateTime(latestCheckin.insertedAt, locale),
  //     nanoId: item.nanoId,
  //     lat: item.lat,
  //     lon: item.lon,
  //     tagId,
  //     iconImageDot: 'circle-dot-' + tagId,
  //     iconImageSymbol: 'circle-symbol-' + tagId,
  //     symbolSortKey: getItemMapSortKey(item),
  //     textColor,
  //   }
  //   if (checkin.itemId === selectedItemId.value) {
  //     selectedItemMapItem.push(newItem)
  //   } else {
  //     engagedData.push(newItem)
  //   }
  //   $log('components:MapBase:updateSources:checkin:engagedData', engagedData)
  // })

  setDataForSource(
    map,
    'engagedPinSource',
    'FeatureCollection',
    newEngagedItems
  )

  if (highlightedItems.value.length > 0) {
    map.setPaintProperty('engagedPinLayer', 'icon-opacity', 0.3)
    map.setPaintProperty('engagedPinLayer', 'text-opacity', 0.3)
  } else {
    map.setPaintProperty('engagedPinLayer', 'icon-opacity', 1)
    map.setPaintProperty('engagedPinLayer', 'text-opacity', 1)
  }

  // highPrioPins items
  // let updateHighPrioPins = false
  // if (itemIds.length > 0 && zoom.value > 12) {
  //   $log('components:MapBase:updateSources:itemIds', itemIds)
  //   itemIds.forEach((itemId) => {
  //     // decide wheter add, keep or remove
  //     if (featured.value.find((featured) => featured.itemId === itemId)) {
  //       // keep or add
  //       if (
  //         highPrioItemsData.value.find((item) => item.id === itemId) ===
  //         undefined
  //       ) {
  //         // add
  //         const item = itemStore.getItemById(itemId)
  //         if (!item) return
  //         if (isVisibleOnFullMap(item) === false) return
  //         const mainTag = getItemMainTag(item, locale)
  //         let tagId = mainTag ? mainTag.id : 0
  //         if (tagId === 0) {
  //           const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //           if (newTag) {
  //             tagId = newTag.id
  //           }
  //         }
  //         const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //         let textColor = ''
  //         if (textColorVar.startsWith('var(')) {
  //           const textColorVarName = textColorVar
  //             .replace('var(', '')
  //             .replace(')', '')
  //           textColor = getComputedStyle(
  //             document.documentElement
  //           ).getPropertyValue(textColorVarName)
  //         } else if (textColorVar.startsWith('#')) {
  //           textColor = textColorVar
  //         }
  //         const newItem = {
  //           itemId: item.id,
  //           title: getItemTitle(item, locale),
  //           subtitle: null,
  //           nanoId: item.nanoId,
  //           lat: item.lat,
  //           lon: item.lon,
  //           tagId,
  //           iconImageDot: 'circle-dot-' + tagId,
  //           iconImageSymbol: 'circle-symbol-' + tagId,
  //           symbolSortKey: getItemMapSortKey(item),
  //           textColor,
  //         }
  //         $log(
  //           'components:MapBase:updateSources:highPrioItems:add:newItem',
  //           newItem
  //         )
  //         highPrioItemsData.value.push(newItem)
  //         updateHighPrioPins = true
  //       }
  //     } else {
  //       // remove
  //       const index = highPrioItemsData.value.findIndex(
  //         (item) => item.id === itemId
  //       )
  //       if (index > -1) {
  //         highPrioItemsData.value.splice(index, 1)
  //         updateHighPrioPins = true
  //         $log(
  //           'components:MapBase:updateSources:highPrioItems:remove:item',
  //           newItem
  //         )
  //       }
  //     }
  //   })
  // } else if (zoom.value > 12) {
  //   highPrioItemsData.value = []
  //   const highPrioItemIds = ref([])
  //   updateHighPrioPins = true
  //   highPrioItemIds.value = featured.value.map((featured) => featured.itemId)
  //   $log(
  //     'components:MapBase:updateSources:highPrioItems',
  //     highPrioItemIds.value.length
  //   )
  //   highPrioItemIds.value.forEach((itemId) => {
  //     if (itemId === selectedItemId.value) return
  //     const item = itemStore.getItemById(itemId)
  //     if (!item) return
  //     if (isVisibleOnFullMap(item) === false) return
  //     const mainTag = getItemMainTag(item, locale)
  //     let tagId = mainTag ? mainTag.id : 0
  //     if (tagId === 0) {
  //       const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //       if (newTag) {
  //         tagId = newTag.id
  //       }
  //     }
  //     const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //     let textColor = ''
  //     if (textColorVar.startsWith('var(')) {
  //       const textColorVarName = textColorVar
  //         .replace('var(', '')
  //         .replace(')', '')
  //       textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //         textColorVarName
  //       )
  //     } else if (textColorVar.startsWith('#')) {
  //       textColor = textColorVar
  //     }
  //     const newItem = {
  //       itemId: item.id,
  //       title: getItemTitle(item, locale),
  //       subtitle: null,
  //       nanoId: item.nanoId,
  //       lat: item.lat,
  //       lon: item.lon,
  //       tagId,
  //       iconImageDot: 'circle-dot-' + tagId,
  //       iconImageSymbol: 'circle-symbol-' + tagId,
  //       symbolSortKey: getItemMapSortKey(item),
  //       textColor,
  //     }
  //     highPrioItemsData.value.push(newItem)
  //   })
  // }

  const newHighPrioItems = mapItems.value.filter((item) => {
    return (
      item.bucket === 'featured' &&
      !item.permanentlyClosedAt &&
      item.bucket !== 'likes' &&
      item.bucket !== 'mentions' &&
      item.bucket !== 'checkins' &&
      item.bucket !== 'lists' &&
      item.id !== selectedItemId.value &&
      !highlightedItemIds.value.includes(item.id)
    )
  })

  $log('components:MapBase:updateSources:newHighPrioItems', newHighPrioItems)

  if (newHighPrioItems) {
    setDataForSource(
      map,
      'highPrioPinSource',
      'FeatureCollection',
      newHighPrioItems
    )
  }

  if (highlightedItems.value.length > 0) {
    map.setPaintProperty('highPrioPinLayerHighAltitude', 'icon-opacity', 0.3)
    map.setPaintProperty('highPrioPinLayerHighAltitude', 'text-opacity', 0.3)
    map.setPaintProperty('highPrioPinLayerLowAltitude', 'icon-opacity', 0.3)
    map.setPaintProperty('highPrioPinLayerLowAltitude', 'text-opacity', 0.3)
  } else {
    map.setPaintProperty('highPrioPinLayerHighAltitude', 'icon-opacity', 1)
    map.setPaintProperty('highPrioPinLayerHighAltitude', 'text-opacity', 1)
    map.setPaintProperty('highPrioPinLayerLowAltitude', 'icon-opacity', 1)
    map.setPaintProperty('highPrioPinLayerLowAltitude', 'text-opacity', 1)
  }

  // lowPrioPins items

  if (zoom.value > 14) {
    const newLowPrioItems = mapItems.value.filter((item) => {
      return (
        item.layer === 'low' &&
        !item.permanentlyClosedAt &&
        item.bucket !== 'featured' &&
        item.bucket !== 'likes' &&
        item.bucket !== 'mentions' &&
        item.bucket !== 'checkins' &&
        item.bucket !== 'lists' &&
        item.id !== selectedItemId.value &&
        !highlightedItemIds.value.includes(item.id)
      )
    })

    $log('components:MapBase:updateSources:newLowPrioItems', newLowPrioItems)
    setDataForSource(
      map,
      'lowPrioPinSource',
      'FeatureCollection',
      newLowPrioItems
    )
    if (highlightedItems.value.length > 0) {
      map.setPaintProperty('lowPrioPinLayer', 'icon-opacity', 0.1)
      map.setPaintProperty('lowPrioPinLayer', 'text-opacity', 0.1)
    } else {
      map.setPaintProperty('lowPrioPinLayer', 'icon-opacity', 1)
      map.setPaintProperty('lowPrioPinLayer', 'text-opacity', 1)
    }
  }

  // const lowPrioItemsData = []
  // const lowPrioItems = ref([])

  // if (zoom.value > 14) {
  //   lowPrioItems.value = items.value.filter(
  //     (item) =>
  //       (item.permanentlyClosedAt !== null ||
  //         getItemMapLayer(item) === 'low') &&
  //       isVisibleOnFullMap(item)
  //   )
  //   $log('components:MapBase:updateSources:lowPrioItems', lowPrioItems.value)

  //   lowPrioItems.value.forEach((item) => {
  //     if (!item) return
  //     if (item.id === selectedItemId.value) return
  //     if (engagedData.find((engagedItem) => engagedItem.nanoId === item.nanoId))
  //       return
  //     if (
  //       highPrioItemsData.value.find(
  //         (highPrioItem) => highPrioItem.nanoId === item.nanoId
  //       )
  //     )
  //       return
  //     const mainTag = getItemMainTag(item, locale)
  //     let tagId = mainTag ? mainTag.id : 0
  //     if (tagId === 0) {
  //       const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //       if (newTag) {
  //         tagId = newTag.id
  //       }
  //     }
  //     const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //     let textColor = ''
  //     if (textColorVar.startsWith('var(')) {
  //       const textColorVarName = textColorVar
  //         .replace('var(', '')
  //         .replace(')', '')
  //       textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //         textColorVarName
  //       )
  //     } else if (textColorVar.startsWith('#')) {
  //       textColor = textColorVar
  //     }
  //     const newItem = {
  //       itemId: item.id,
  //       title: getItemTitle(item, locale),
  //       subtitle: null,
  //       nanoId: item.nanoId,
  //       lat: item.lat,
  //       lon: item.lon,
  //       tagId,
  //       iconImageDot: 'circle-dot-' + tagId,
  //       iconImageSymbol: 'circle-symbol-' + tagId,
  //       symbolSortKey: getItemMapSortKey(item),
  //       textColor,
  //       permanentlyClosedAt: item.permanentlyClosedAt,
  //     }
  //     lowPrioItemsData.push(newItem)
  //   })
  // }
  // $log('components:MapBase:updateSources:lowPrioItems:data', lowPrioItemsData)
  // setDataForSource(
  //   map,
  //   'lowPrioPinSource',
  //   'FeatureCollection',
  //   lowPrioItemsData
  // )
  // if (highlightedItemsMapItems.length > 0) {
  //   map.setPaintProperty('lowPrioPinLayer', 'icon-opacity', 0.4)
  //   map.setPaintProperty('lowPrioPinLayer', 'text-opacity', 0.4)
  // } else {
  //   map.setPaintProperty('lowPrioPinLayer', 'icon-opacity', 1)
  //   map.setPaintProperty('lowPrioPinLayer', 'text-opacity', 1)
  // }

  // normal pins
  if (zoom.value > 13) {
    const newNormalItems = mapItems.value.filter((item) => {
      return (
        !item.permanentlyClosedAt &&
        (item.layer === 'normal' || item.layer === 'high') &&
        item.bucket !== 'featured' &&
        item.bucket !== 'likes' &&
        item.bucket !== 'mentions' &&
        item.bucket !== 'checkins' &&
        item.bucket !== 'lists' &&
        item.id !== selectedItemId.value &&
        !highlightedItemIds.value.includes(item.id)
      )
    })
    $log('components:MapBase:updateSources:newNormalItems', newNormalItems)
    setDataForSource(
      map,
      'defaultPinSource',
      'FeatureCollection',
      newNormalItems
    )
    if (highlightedItems.value.length > 0) {
      map.setPaintProperty('defaultPinLayer', 'icon-opacity', 0.3)
      map.setPaintProperty('defaultPinLayer', 'text-opacity', 0.3)
    } else {
      map.setPaintProperty('defaultPinLayer', 'icon-opacity', 1)
      map.setPaintProperty('defaultPinLayer', 'text-opacity', 1)
    }
  }

  // const itemData = []
  // $log('components:MapBase:updateSources:defaultItems')
  // if (zoom.value > 13) {
  //   items.value.forEach((item) => {
  //     if (item.id === selectedItemId.value) return
  //     if (engagedData.find((engagedItem) => engagedItem.nanoId === item.nanoId))
  //       return
  //     if (
  //       highPrioItemsData.value.find(
  //         (highPrioItem) => highPrioItem.nanoId === item.nanoId
  //       )
  //     )
  //       return
  //     if (
  //       lowPrioItemsData.find(
  //         (lowPrioItem) => lowPrioItem.nanoId === item.nanoId
  //       )
  //     )
  //       return
  //     if (isVisibleOnFullMap(item) === false) return
  //     const mainTag = getItemMainTag(item, locale)
  //     let tagId = mainTag ? mainTag.id : 0
  //     if (tagId === 0) {
  //       const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
  //       if (newTag) {
  //         tagId = newTag.id
  //       }
  //     }
  //     const textColorVar = `${colors.tag[mainTag.bgColor]}`
  //     let textColor = ''
  //     if (textColorVar.startsWith('var(')) {
  //       const textColorVarName = textColorVar
  //         .replace('var(', '')
  //         .replace(')', '')
  //       textColor = getComputedStyle(document.documentElement).getPropertyValue(
  //         textColorVarName
  //       )
  //     } else if (textColorVar.startsWith('#')) {
  //       textColor = textColorVar
  //     }
  //     const newItem = {
  //       itemId: item.id,
  //       title: getItemTitle(item, locale),
  //       subtitle: null,
  //       nanoId: item.nanoId,
  //       lat: item.lat,
  //       lon: item.lon,
  //       tagId,
  //       iconImageDot: 'circle-dot-' + tagId,
  //       iconImageSymbol: 'circle-symbol-' + tagId,
  //       symbolSortKey: getItemMapSortKey(item),
  //       textColor,
  //     }
  //     if (item.id === selectedItemId.value) {
  //       selectedItemMapItem.push(newItem)
  //     } else {
  //       itemData.push(newItem)
  //     }
  //   })
  // }
  // $log('components:MapBase:updateSources:defaultItems:data', itemData)
  // setDataForSource(map, 'defaultPinSource', 'FeatureCollection', itemData)
  // if (highlightedItemsMapItems.length > 0) {
  //   map.setPaintProperty('defaultPinLayer', 'icon-opacity', 0.4)
  //   map.setPaintProperty('defaultPinLayer', 'text-opacity', 0.4)
  // } else {
  //   map.setPaintProperty('defaultPinLayer', 'icon-opacity', 1)
  //   map.setPaintProperty('defaultPinLayer', 'text-opacity', 1)
  // }

  // highlighted items
  const highlightedItemsData = []
  $log('components:MapBase:updateSources:highlightedItems')
  highlightedItems.value.forEach((item) => {
    if (item.id === selectedItemId.value) return
    // if (highlightedItemsMapItems.length > 0) {
    //   const newItem = highlightedItemsMapItems.find(
    //     (highlightedItem) => highlightedItem.nanoId === item.nanoId
    //   )
    //   if (newItem) {
    //     highlightedItemsData.push(newItem)
    //     return
    //   }
    // }
    const mainTag = getItemMainTag(item, locale)
    let tagId = mainTag && mainTag.id ? mainTag.id : 0
    if (tagId === 0) {
      const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
      if (newTag) {
        tagId = newTag.id
      }
    }
    const textColorVar = `${colors.tag[mainTag.bgColor]}`
    let textColor = ''
    if (textColorVar.startsWith('var(')) {
      const textColorVarName = textColorVar.replace('var(', '').replace(')', '')
      textColor = getComputedStyle(document.documentElement).getPropertyValue(
        textColorVarName
      )
    } else if (textColorVar.startsWith('#')) {
      textColor = textColorVar
    }
    const newItem = {
      itemId: item.id,
      title: getItemTitle(item, locale),
      subtitle: null,
      nanoId: item.nanoId,
      lat: item.lat,
      lon: item.lon,
      tagId,
      iconImageDot: 'circle-dot-' + tagId,
      iconImageSymbol: 'circle-symbol-' + tagId,
      symbolSortKey: getItemMapSortKey(item),
      textColor,
    }
    highlightedItemsData.push(newItem)
  })
  $log(
    'components:MapBase:updateSources:highlightedItemsData',
    highlightedItemsData
  )
  setDataForSource(
    map,
    'highlightedItemsSource',
    'FeatureCollection',
    highlightedItemsData
  )

  const selectedItemData = []

  if (selectedItemId.value !== null) {
    if (selectedItemMapItem.length > 0) {
      const newItem = selectedItemMapItem[0]
      newItem.iconImagePinSymbol = 'pin-symbol-' + newItem.tagId
      $log('components:MapBase:updateSources:newItem', newItem)
      selectedItemData.push(newItem)
    } else {
      const mainTag = getItemMainTag(selectedItem.value, locale)
      let tagId = mainTag ? mainTag.id : 0
      if (tagId === 0) {
        const newTag = tagStore.getTagByKeyValue('symbolHelper', 'noCat')
        if (newTag) {
          tagId = newTag.id
        }
      }
      const textColorVar = `${colors.tag[mainTag.bgColor]}`
      let textColor = ''
      if (textColorVar.startsWith('var(')) {
        const textColorVarName = textColorVar
          .replace('var(', '')
          .replace(')', '')
        textColor = getComputedStyle(document.documentElement).getPropertyValue(
          textColorVarName
        )
      } else if (textColorVar.startsWith('#')) {
        textColor = textColorVar
      }

      const newItem = {
        itemId: selectedItem.value.id,
        title: getItemTitle(selectedItem.value, locale),
        subtitle: null,
        nanoId: selectedItem.value.nanoId,
        lat: selectedItem.value.lat,
        lon: selectedItem.value.lon,
        tagId,
        iconImageDot: 'circle-dot-' + tagId,
        iconImageSymbol: 'circle-symbol-' + tagId,
        iconImagePinSymbol: 'pin-symbol-' + tagId,
        symbolSortKey: getItemMapSortKey(selectedItem.value),
        textColor,
      }
      $log('components:MapBase:updateSources:newItem', newItem)
      selectedItemData.push(newItem)
    }

    setDataForSource(
      map,
      'selectedItemSource',
      'FeatureCollection',
      selectedItemData
    )
  } else {
    setDataForSource(map, 'selectedItemSource', 'FeatureCollection', [])
  }
  $log('components:MapBase:updateSources:end')
}

function addAdditionalSourceAndLayer() {
  $log('components:MapBase:addAdditionalSourceAndLayer')
  $log(
    'components:MapBase:addAdditionalSourceAndLayer:selectedStyle',
    selectedStyle
  )

  // likes, mentions, checkins, lists. all permanent
  const engageSourceLayer = {
    map,
    sourceId: 'engagedPinSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'engagedPinLayer',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'step',
        ['zoom'],
        ['get', 'iconImageDot'],
        13,
        ['get', 'iconImageSymbol'],
      ],
      'icon-size': ['step', ['zoom'], 0.2, 13, 0.4, 16, 0.5],
      'symbol-sort-key': ['get', 'symbolSortKey'],
      // 'symbol-z-elevate': true,
      'text-field': [
        'format',
        ['get', 'title'],
        {},
        ['get', 'subtitle'],
        { 'font-scale': 0.9 },
      ],
      'text-font': ['DIN Pro Bold'],
      'text-allow-overlap': true,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 16, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'poi-label',
    minzoom: 0,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  // high important permanent items - Hight Altitude
  const highPrioPinHighAltitudeSourceLayer = {
    map,
    sourceId: 'highPrioPinSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'highPrioPinLayerHighAltitude',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'step',
        ['zoom'],
        ['get', 'iconImageDot'],
        13,
        ['get', 'iconImageSymbol'],
      ],
      'icon-size': ['step', ['zoom'], 0.2, 13, 0.4, 16, 0.5],
      'symbol-sort-key': ['get', 'symbolSortKey'],
      'text-field': [
        'format',
        ['get', 'title'],
        {},
        ['get', 'subtitle'],
        { 'font-scale': 0.9 },
      ],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': true,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 16, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'engagedPinLayer',
    minzoom: 0,
    maxzoom: 13,
    slot: 'top',
    selectedStyle,
  }

  // high important permanent items - Low Altitude
  const highPrioPinLowAltitudeSourceLayer = {
    map,
    sourceId: 'highPrioPinSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'highPrioPinLayerLowAltitude',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'step',
        ['zoom'],
        ['get', 'iconImageDot'],
        13,
        ['get', 'iconImageSymbol'],
      ],
      'icon-size': ['step', ['zoom'], 0.2, 13, 0.4, 16, 0.5],
      'symbol-sort-key': ['get', 'symbolSortKey'],
      'text-field': [
        'format',
        ['get', 'title'],
        {},
        ['get', 'subtitle'],
        { 'font-scale': 0.9 },
      ],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': true,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 16, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'engagedPinLayer',
    minzoom: 13,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  // all the rest
  const defaultSourceLayer = {
    map,
    sourceId: 'defaultPinSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'defaultPinLayer',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': false,
      'icon-image': [
        'step',
        ['zoom'],
        ['get', 'iconImageDot'],
        16,
        ['get', 'iconImageSymbol'],
      ],
      'icon-size': ['step', ['zoom'], 0.2, 16, 0.5],
      'symbol-sort-key': ['get', 'symbolSortKey'],
      // 'symbol-z-elevate': true,
      'text-field': ['get', 'title'],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': false,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 17, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'highPrioPinLayerLowAltitude',
    minzoom: 13,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  // low important items
  const lowPrioPinSourceLayer = {
    map,
    sourceId: 'lowPrioPinSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'lowPrioPinLayer',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'step',
        ['zoom'],
        ['get', 'iconImageDot'],
        17,
        ['get', 'iconImageSymbol'],
      ],
      'icon-size': ['step', ['zoom'], 0.2, 17, 0.5],
      'symbol-sort-key': ['get', 'symbolSortKey'],
      // 'symbol-z-elevate': true,
      'text-field': [
        'format',
        ['get', 'title'],
        {},
        ['get', 'subtitle'],
        { 'font-scale': 0.9 },
      ],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': true,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 17, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'defaultPinLayer',
    minzoom: 16,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  // selected item
  const selectedItemsSourceLayer = {
    map,
    sourceId: 'selectedItemSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'selectedItemLayer',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': ['get', 'iconImagePinSymbol'],
      'icon-offset': [0, -42],
      'icon-size': 0.5,
      'symbol-sort-key': ['get', 'symbolSortKey'],
      'text-field': [
        'format',
        ['get', 'title'],
        {},
        ['get', 'subtitle'],
        { 'font-scale': 0.9 },
      ],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': true,
      'text-offset': [0, -0.3],
      'text-justify': 'auto',
      'text-variable-anchor': ['top'],
      'text-size': 12,
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: null,
    minzoom: 0,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  // highlighted items
  const highlightedItemsSourceLayer = {
    map,
    sourceId: 'highlightedItemsSource',
    sourceDataType: 'FeatureCollection',
    layerId: 'highlightedItemsLayer',
    layerType: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': ['get', 'iconImageSymbol'],
      'icon-size': 0.5,
      'symbol-sort-key': ['get', 'symbolSortKey'],
      'text-field': ['get', 'title'],
      'text-font': ['DIN Pro Medium'],
      'text-allow-overlap': true,
      'text-radial-offset': 1.1,
      'text-justify': 'auto',
      'text-variable-anchor': ['top', 'left', 'right'],
      'text-size': ['step', ['zoom'], 0, 17, 12],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': mapHaloColor.value,
      'text-color': ['get', 'textColor'],
    },
    positionBelowLayer: 'selectedItemLayer',
    minzoom: 0,
    maxzoom: 24,
    slot: 'top',
    selectedStyle,
  }

  if (selectedStyle.value.useMapboxStandardStyle) {
    $log(
      'components:MapBase:addAdditionalSourceAndLayer:useMapboxStandardStyle'
    )
    // add layers in reverse order
    initSourceAndLayer(lowPrioPinSourceLayer)
    initSourceAndLayer(defaultSourceLayer)
    initSourceAndLayer(highPrioPinHighAltitudeSourceLayer)
    initSourceAndLayer(highPrioPinLowAltitudeSourceLayer)
    initSourceAndLayer(engageSourceLayer)
    initSourceAndLayer(highlightedItemsSourceLayer)
    initSourceAndLayer(selectedItemsSourceLayer)
  } else {
    // add layers in reference to each other (positionBelowLayer is used)
    initSourceAndLayer(engageSourceLayer)
    initSourceAndLayer(highPrioPinHighAltitudeSourceLayer)
    initSourceAndLayer(highPrioPinLowAltitudeSourceLayer)
    initSourceAndLayer(defaultSourceLayer)
    initSourceAndLayer(lowPrioPinSourceLayer)
    initSourceAndLayer(selectedItemsSourceLayer)
    initSourceAndLayer(highlightedItemsSourceLayer)
  }

  // order matters: last added layer is on top
  addMouseEvents(map, 'lowPrioPinLayer')
  addMouseEvents(map, 'defaultPinLayer')
  addMouseEvents(map, 'highPrioPinLayerHighAltitude')
  addMouseEvents(map, 'highPrioPinLayerLowAltitude')
  addMouseEvents(map, 'engagedPinLayer')
  addMouseEvents(map, 'highlightedItemsLayer')

  seedMapItemsHighAltitude()

  if (map.getZoom() < 12) {
    updateSources()
  }

  fetchMapItemsData()
}

const storeMapState = () => {}

// ------------
// mapConstruct
// ------------

const mapConstruct = () => {
  let mapCenter = null
  let mapZoom = null

  const mapConstructParams = {
    center: center.value,
    zoom: zoom.value,
    bearing: bearing.value,
    pitch: pitch.value,
  }
  controllerStore.addDevInfo({ mapConstructParams })

  if (zoom.value && typeof zoom.value === 'number') {
    mapZoom = zoom.value
  } else {
    mapZoom = 5
  }

  if (selectedItem.value && reconstructMap.value === false) {
    $log('components:MapBase:onMounted:selectedItem', selectedItem)
    mapCenter = [selectedItem.value.lon, selectedItem.value.lat]
    mapZoom = 17
  } else if (center.value) {
    try {
      const lng = center.value.lng
      const lat = center.value.lat
      if (lng && lat) {
        const centerLatLng = new mapboxgl.LngLat(lng, lat)
        if (centerLatLng) {
          mapCenter = centerLatLng
        }
      }
    } catch {
      mapCenter = [9.543834599924821, 47.2202287211137]
    }
  } else {
    // NYC
    mapCenter = [9.543834599924821, 47.2202287211137]
  }

  let mapBearing = null
  if (bearing.value && typeof bearing.value === 'number') {
    mapBearing = bearing.value
  } else {
    mapBearing = 0
  }

  let mapPitch = null
  if (pitch.value && typeof pitch.value === 'number') {
    mapPitch = pitch.value
  } else {
    mapPitch = 0
  }

  reconstructMap.value = false

  mapboxgl.accessToken = useRuntimeConfig().public.mapboxAccessToken
  const mapOptions = {
    container: 'map',
    style: styleUrl.value,
    center: mapCenter,
    zoom: mapZoom,
    bearing: mapBearing,
    pitch: mapPitch,
    attributionControl: false,
  }
  map = new mapboxgl.Map(mapOptions)
  map.showCollisionBoxes = false
  if (!selectedItem.value) {
    const padding = getPadding()
    map.easeTo({ padding, animate: false })

    setFullBounds() // TODO:setFullBounds() is already called after any move - possible conflict?
    mapVisibleBounds.value = map.getBounds()
    itemStore.fetchItemsByBox(
      mapFullSwLngLat.value.lng,
      mapFullSwLngLat.value.lat,
      mapFullNeLngLat.value.lng,
      mapFullNeLngLat.value.lat
    )
  } else {
    const padding = getPadding()
    map.easeTo({ padding, animate: false })
  }

  if (showDebugInfo !== null) {
    map.showPadding = showDebugInfo.value
  }

  map.on('styleimagemissing', (e) => {
    // $log('components:MapBase:map.on(styleimagemissing):e', e)
    const iconImageName = e.id

    let url = null
    let img = null
    let tagId = null
    let width = null
    let height = null
    if (iconImageName.startsWith('circle-symbol-')) {
      tagId = Number(iconImageName.replace('circle-symbol-', ''))
      url = mapSvgUrl('circle-symbol', tagId, colorMode.value)
      width = 48
      height = 48
    } else if (iconImageName.startsWith('pin-symbol-')) {
      tagId = Number(iconImageName.replace('pin-symbol-', ''))
      url = mapSvgUrl('pin-symbol', tagId, colorMode.value)
      width = 64
      height = 88
    } else if (iconImageName.startsWith('circle-dot-')) {
      tagId = Number(iconImageName.replace('circle-dot-', ''))
      url = mapSvgUrl('circle-dot', tagId, colorMode.value)
      width = 48
      height = 48
    } else {
      return
    }

    if (!iconImageName) return

    const createImage = (width, height) => {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'rgba(0, 0, 0, 0)'
      ctx.fillRect(0, 0, width, height)

      const img = new Image(width, height)
      img.src = canvas.toDataURL()

      return img
    }

    img = new Image(width, height)

    const tempImg = createImage(width, height)

    map.addImage(iconImageName, tempImg)

    img.map = map // pass map into the onload function so that it's available via this
    img.eId = iconImageName
    img.onload = function () {
      map.updateImage(this.eId, img)
    }
    img.src = url
    // console.log(
    //   'components/Map.vue: map.on(styleimagemissing): img.src: ',
    //   img.src
    // )
  })

  map.on('style.load', () => {
    $log('components:MapBase:map.on(style.load)')
    $log(
      'components:MapBase:map.on(style.load):lightPreset',
      selectedStyle.value[colorMode.value].lightPreset
    )
    $log('components:MapBase:map.on(style.load):colorMode', colorMode.value)
    if (selectedStyle.value.useMapboxStandardStyle) {
      map.setConfigProperty('basemap', 'showPlaceLabels', true)
      map.setConfigProperty('basemap', 'showRoadLabels', true)
      map.setConfigProperty('basemap', 'showPointOfInterestLabels', false)
      map.setConfigProperty('basemap', 'showTransitLabels', false)
      map.setConfigProperty(
        'basemap',
        'lightPreset',
        selectedStyle.value[colorMode.value].lightPreset
      )
      // map.setConfigProperty('basemap', 'font', ['DIN Pro Bold'])
    }

    addAdditionalSourceAndLayer()
  })

  map.on('load', () => {
    $log('components:MapBase:map.on(load)')

    // Get all layers of the map
    // const layers = map.getStyle().layers
    // for (const layer of layers) {
    //   console.log('components/Map.vue: layer', layer.id)
    // }
  })

  const presentContextMenu = (e) => {
    $log('components:MapBase:presentContextMenu:e', e)
    const coordinates = e.lngLat
    $log('components:MapBase:presentContextMenu:coordinates', coordinates)
    if (popup.value) {
      popup.value.remove()
    }
    popup.value = new mapboxgl.Popup({ closeButton: false })
      .setLngLat(coordinates)
      .setHTML('<div id="map-context-menu" />')
      .addTo(map)
    // TODO: check if other popups are open and close them
    nextTick(() => {
      const popupComp = h(MapContextMenu, {
        e,
        onClick: (e) => {
          $log(
            'components:MapBase:presentContextMenu:Context menu clicked:e',
            e
          )
        },
      })
      render(popupComp, document.getElementById('map-context-menu'))
    })
  }

  const initTouchContextMenu = () => {
    let touchTimeout = null
    const clearTouchTimeout = () => {
      if (touchTimeout) {
        clearTimeout(touchTimeout)
        touchTimeout = null
      }
    }
    map.on('touchstart', (e) => {
      $log('components:MapBase:map.on(touchstart):e', e)
      if (e.originalEvent.touches.length > 1) {
        return
      }
      touchTimeout = setTimeout(() => {
        $log('components:MapBase:map.on(touchstart):present context menu', e)
        presentContextMenu(e)
      }, 500)
    })
    map.on('touchend', clearTouchTimeout)
    map.on('touchcancel', clearTouchTimeout)
    map.on('touchmove', clearTouchTimeout)
    map.on('pointerdrag', clearTouchTimeout)
    map.on('pointermove', clearTouchTimeout)
    map.on('moveend', clearTouchTimeout)
    map.on('gesturestart', clearTouchTimeout)
    map.on('gesturechange', clearTouchTimeout)
    map.on('gestureend', clearTouchTimeout)
  }

  initTouchContextMenu()

  map.on('contextmenu', (e) => {
    $log('components:MapBase:map.on(contextmenu):e', e)
    presentContextMenu(e)
  })
  map.on('movestart', () => {
    $log('components:MapBase:map.on(movestart)')
  })
  // map.on('mousemove', (e) => {
  //   $log('components:MapBase:map.on(move):point', JSON.stringify(e.point))
  // })
  map.on('move', () => {
    // $log('components:MapBase:map.on(move)')

    zoom.value = map.getZoom()

    if (showDebugInfo) {
      const newDevInfo = {
        zoom: useRound(zoom.value * 100).value / 100,
      }
      controllerStore.addDevInfo(newDevInfo)

      const newDevInfoWatchState = {
        watchState: getWatchState(),
      }
      controllerStore.addDevInfo(newDevInfoWatchState)
    }
  })

  map.on('moveend', () => {
    $log('components:MapBase:map.on(moveend)')
    zoom.value = map.getZoom()
    bearing.value = map.getBearing()
    pitch.value = map.getPitch()
    center.value = map.getCenter()

    if (showDebugInfo) {
      const newDevInfo = {
        zoom: useRound(zoom.value * 100).value / 100,
        bearing: bearing.value,
        pitch: pitch.value,
      }
      controllerStore.addDevInfo(newDevInfo)
    }

    checkWatchState()

    setFullBounds()

    mapVisibleBounds.value = map.getBounds()
    $log(
      'components:MapBase:map.on(moveend):mapVisibleBounds',
      mapVisibleBounds.value
    )
    fetchMapItemsData()

    storeMapState()
  })

  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    showUserHeading: true,
    trackUserLocation: true,
  })
  map.addControl(geolocate)
  map.on('load', () => {
    // geolocate.trigger()
  })
  geolocate.on('trackuserlocationstart', () => {
    checkWatchState()
  })
  geolocate.on('trackuserlocationend', () => {
    checkWatchState()
  })
  geolocate.on('error', () => {
    $log('components:MapBase:geolocate:An error event has occurred')
    checkWatchState()
  })

  map.on('error', () => {
    mapError += 'A map error occured. \n'
    console.log('A error event occurred.')
  })
  map.on('webglcontextlost', () => {
    const now = formatISO(new Date())
    mapError +=
      'A webglcontextlost event occurred. (' +
      documentVisibility.value +
      ', ' +
      now +
      ') \n'
    console.log('A webglcontextlost event occurred.')
  })
  map.on('webglcontextrestored', () => {
    const now = formatISO(new Date())
    mapError +=
      'A webglcontextrestored event occurred. (' +
      documentVisibility.value +
      ', ' +
      now +
      ')\n'
    console.log('A webglcontextrestored event occurred.')
  })
}

const mapReconstruct = () => {
  $log('components:MapBase:mapReConstruct')
  reconstructMap.value = true
  map.remove()
  mapConstruct()
}

const setFullBounds = () => {
  // set full bounds (without padding)
  const swScreenPixels = { x: 0, y: height.value }
  const neScreenPixels = { x: width.value, y: 0 }
  if (!map) return
  $log('components:MapBase:setFullBounds:swScreenPixels', swScreenPixels)
  $log('components:MapBase:setFullBounds:neScreenPixels', neScreenPixels)
  try {
    const swLngLat = map.unproject(swScreenPixels)
    const neLngLat = map.unproject(neScreenPixels)

    if (swLngLat && neLngLat) {
      mapFullSwLngLat.value = swLngLat
      mapFullNeLngLat.value = neLngLat
    }
    $log('components:MapBase:setFullBounds:swLngLat', swLngLat)
    $log('components:MapBase:setFullBounds:neLngLat', neLngLat)
  } catch (e) {
    $log('components:MapBase:setFullBounds:error', e)
    Sentry.captureException(e)
  }
}

const currentMapFullBoundingBox = computed(() => {
  const southWest = mapFullSwLngLat.value
  const northEast = mapFullNeLngLat.value

  const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast)
  return boundingBox
})

const isVisibleOnFullMap = (itemMayBeRef) => {
  if (!itemMayBeRef) return false
  const item = unref(itemMayBeRef)

  const lngLat = [item.lon, item.lat]

  const isVisible = currentMapFullBoundingBox.value.contains(lngLat)
  $log('components:MapBase:isVisibleOnFullMap:isVisible', isVisible)
  return isVisible
}

// ---------------
// -- onMounted ()
// ---------------

// MARK: onMounted

onMounted(() => {
  $log('components:MapBase:onMounted')
  mapConstruct()
})

onBeforeUpdate(() => {
  $log('components:MapBase:onBeforeUpdate')
})

onUpdated(() => {
  $log('components:MapBase:onUpdated')
})

const straightenMap = () => {
  if (!map) return
  if (map.getPitch() !== 0) {
    map.easeTo({
      pitch: 0,
      animate: false,
    })
  } else if (map.getBearing() !== 0) {
    map.easeTo({
      bearing: 0,
      animate: false,
    })
  }
}
</script>
<style>
.mapboxgl-ctrl-geolocate {
  display: none !important;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-default-map-size {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
}
</style>
