<template>
  <div
    class="bg-gradient-to-r from-violet-700/80 via-blue-500/80 to-sky-500/80 text-white font-semibold rounded px-1 leading-relaxed"
    :class="{
      'text-[0.5125rem]': props.size === 'xs',
      'text-[0.6rem]': props.size === 'sm',
      'text-[0.6825rem]': props.size === 'md',
      'text-[0.75rem]': props.size === 'lg',
    }"
  >
    <span class="whitespace-nowrap">Pro</span>
  </div>
</template>
<script setup>
// add size parameter
const props = defineProps({
  size: {
    type: String,
    default: 'md',
  },
})
</script>
