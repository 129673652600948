export const useMapPersistedStore = defineStore('mapPersisted', {
  state: () => ({
    selectedStyleId: 1,
    center: null,
    zoom: null,
    pitch: null,
    bearing: null,
  }),
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 60 * 60 * 24 * 90,
    }),
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMapPersistedStore, import.meta.hot))
}
