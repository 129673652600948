<template>
  <div
    class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto drop-shadow"
  >
    <div class="flex flex-col">
      <div class="flex justify-between items-center border-b py-3 px-4">
        <div class="w-6">&nbsp;</div>
        <div class="text-xl font-medium text-panel-accent-text">
          {{ $t('bottomSheetMapStyle.title') }}
        </div>
        <div
          class="cursor-pointer"
          @click="controllerStore.closeBottomSheetMapStyle"
        >
          <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
        </div>
      </div>
      <div
        class="flex flex-col mx-auto space-y-4 font-normal text-sm text-panel-accent-text px-4 pt-4 pb-6"
      >
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
          <div
            v-for="(style, index) in availableStyles"
            :key="index"
            class="cursor-pointer space-y-1"
            @click="selectStyle(style.id)"
          >
            <div
              class="w-32 border border-white/50 rounded overflow-hidden"
              :class="{
                'ring-2 ring-blue-400': selectedStyleId === style.id,
              }"
            >
              <img
                class="object-cover w-32 aspect-[5/3]"
                :src="'/images/maps/' + style.previewImage"
              />
            </div>

            <div class="text-xs">{{ style.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const controllerStore = useControllerStore()
const mapStore = useMapStore()
const { availableStyles } = storeToRefs(mapStore)
const mapPersistedStore = useMapPersistedStore()
const { selectedStyleId } = storeToRefs(mapPersistedStore)

const selectStyle = (styleId) => {
  selectedStyleId.value = styleId
  controllerStore.closeBottomSheetMapStyle()
}
</script>
