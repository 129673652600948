<template>
  <div class="fixed z-40 h-screen w-full top-0 bg-panel-background-behind/70">
    <div
      class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto p-4"
    >
      <div class="flex flex-col space-y-6 mt-2">
        <div class="flex justify-between items-center">
          <div class="text-xl font-medium text-panel-accent-text">
            {{ $t('bottomSheetMention.title') }}
          </div>
          <div
            class="cursor-pointer"
            @click="controllerStore.closeBottomSheetMention"
          >
            <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
          </div>
        </div>
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <img
              v-if="src"
              class="inline-block h-10 w-10 rounded-full object-cover"
              :src="src"
              alt=""
            />
          </div>
          <div class="min-w-0 flex-1">
            <Form ref="form" class="relative" @submit="onSubmit">
              <div
                class="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-panel-textarea-ring focus-within:ring-2 focus-within:ring-panel-textarea-ring-focus bg-panel-textarea-background"
              >
                <label for="note" class="sr-only">Add your note</label>
                <Field
                  id="note"
                  as="textarea"
                  rows="3"
                  name="note"
                  class="block w-full resize-none border-0 bg-transparent p-2 text-panel-textarea-text placeholder:text-panel-textarea-placeholder !outline-none text-md sm:text-sm sm:leading-6 focus:ring-0"
                  :placeholder="$t('bottomSheetMention.textareaPlaceholder')"
                  :rules="validateTextarea"
                  :validate-on-blur="false"
                />

                <!-- Spacer element to match the height of the toolbar -->
                <div class="py-2" aria-hidden="true">
                  <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                  <div class="py-px">
                    <div class="h-9" />
                  </div>
                </div>
              </div>

              <div
                class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2"
              >
                <div class="flex items-center space-x-5">
                  <div class="flex items-center">
                    <SvgIcon
                      name="lock-closed"
                      type="mini"
                      class="h-4 w-4 fill-panel-textarea-placeholder mr-1"
                    />
                    <div class="text-panel-textarea-placeholder text-xs mr-2">
                      {{ $t('general.visibility.private') }}
                    </div>
                    <div
                      class="flex bg-amber-100 text-xs rounded p-1 items-center"
                    >
                      {{ $t('bottomSheetMention.publicComingSoon') }}
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <button
                    type="submit"
                    class="inline-flex items-center rounded-md bg-panel-action px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-panel-action-hover"
                  >
                    {{ $t('bottomSheetMention.postCTA') }}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Form, Field } from 'vee-validate'
const { $log } = useNuxtApp()
const controllerStore = useControllerStore()
const itemStore = useItemStore()
const { selectedItemId } = storeToRefs(itemStore)

const profileStore = useProfileStore()
const { isQuoPro, profile } = storeToRefs(profileStore)

const client = useSupabaseClient()
const src = ref('')
const form = ref('')

const downloadImage = async () => {
  try {
    $log('BottomSheetMention: profile: ', profile.value)
    if (!profile.value.avatarUrl) return
    const { data, error } = await client.storage
      .from('avatars')
      .download(profile.value.avatarUrl)
    if (error) throw error
    $log('BottomSheetMention: ', data)
    src.value = URL.createObjectURL(data)
  } catch (error) {
    console.error(
      'BottomSheetMention: Error downloading image: ',
      error.message
    )
  }
}

await downloadImage()

const loading = ref(false)

const mentionStore = useMentionStore()

const handlePost = async (values) => {
  if (!isQuoPro.value) {
    $log('BottomSheetMention: not quo pro')
    return
  }
  if (selectedItemId.value) {
    values.itemId = selectedItemId.value
    const answer = await mentionStore.createMention(values)
    $log('BottomSheetMention: answer: ', answer)
    if (answer.id) {
      form.value.resetForm()
      controllerStore.closeBottomSheetMention()
    }
  }
}

const onSubmit = async (values) => {
  $log('submit: values: ', values)

  loading.value = true
  await handlePost(values)
  loading.value = false
}

const validateTextarea = (value) => {
  if (validateLength(value)) {
    return true
  } else {
    return false
  }
}

const validateLength = (value) => {
  // only check, if pw is not empty
  if (!value) {
    return false
  }
  // check if password contains special character
  if (value.length === 0) {
    return false
  }
  return true
}
</script>
