<template>
  <div class="grid grid-cols-1 text-sm gap-y-1 text-gray-700">
    <div
      class="flex justify-center p-2 rounded cursor-pointer"
      :class="{
        'bg-gray-100': !copied,
        'hover:bg-gray-200': !copied,
        'bg-green-100': copied,
        'hover:bg-green-200': copied,
      }"
      @click="copy(source)"
    >
      {{ coordinatesShown.lat }}, {{ coordinatesShown.lng }}
      <span v-if="!isSupported" class="text-xs text-gray-500">
        (copy not supported)</span
      >
    </div>
    <div
      v-if="isQuoPro"
      class="flex justify-center p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer"
      @click="setNewPlaceLatLng()"
    >
      Add a new place
    </div>
  </div>
</template>
<script setup>
import { useClipboard } from '@vueuse/core'
import { useRound } from '@vueuse/math'
const props = defineProps({
  e: {
    type: Object,
    required: true,
  },
})
const { e } = toRefs(props)
const profileStore = useProfileStore()
const { currentUser } = storeToRefs(profileStore)
const isQuoPro = computed(() => {
  if (currentUser.value && currentUser.value.memberNumber) {
    return true
  } else {
    return false
  }
})
// console.log('components/MapContextMenu.vue: e: ', e)
// console.log('components/MapContextMenu.vue: e.lngLat: ', e.value.lngLat)
const coordinates = e.value.lngLat
const coordinatesShown = ref({})
coordinatesShown.value = {
  lng: useRound(coordinates.lng * 1000000).value / 1000000,
  lat: useRound(coordinates.lat * 1000000).value / 1000000,
}
const source = ref('')
source.value = coordinates.lat + ', ' + coordinates.lng
const { copy, copied, isSupported } = useClipboard({ source })
const controllerStore = useControllerStore()
const setNewPlaceLatLng = () => {
  controllerStore.setNewPlaceLat(coordinates.lat)
  controllerStore.setNewPlaceLon(coordinates.lng)
  controllerStore.openBottomSheetPlaceCreateQuo()
}
</script>
