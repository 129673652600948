export const useAddress = () => {
  const getItemResultAddress = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    const address = { street: '', houseNumber: '', city: '', country: '' }
    const returnAddress = []
    if (item && item.addresses && item.addresses.length > 0) {
      const street = item.addresses.find((address) => address.type === 'street')
      if (street) {
        address.street = street.value
        returnAddress.push(street.value)
      }
      const city = item.addresses.find((address) => address.type === 'city')
      if (city) {
        address.city = city.value
        returnAddress.push(city.value)
      }
      const country = item.addresses.find(
        (address) => address.type === 'country'
      )
      if (country) {
        address.country = country.value
        returnAddress.push(country.value)
      }
      // if (returnAddress.length > 0) {
      //   return returnAddress
      // }
    }
    return address
  }

  return {
    getItemResultAddress,
  }
}
