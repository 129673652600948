<template>
  <div
    ref="searchResultSheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-x-hidden pointer-events-auto sm:h-full overscroll-y-none"
    :style="xs ? 'height: ' + height + 'px;' : ''"
    :class="isDragging ? 'overflow-y-hidden' : 'overflow-y-auto'"
  >
    <div class="sticky top-0">
      <div class="relative bg-panel-background/90">
        <div class="h-10">&nbsp;</div>
        <div
          ref="searchResultMoveHandle"
          class="absolute w-full h-full top-0"
        ></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between items-center">
            <div
              class="p-2 flex items-center space-x-1.5 text-panel-accent-text text-2xl font-semibold"
            >
              <SvgIcon
                :name="highlight.icon"
                :type="highlight.iconType"
                class="h-6 w-6"
              />
              <div>{{ highlight.translation.name }}</div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 py-3 mt-2 flex text-sm">
      <div
        class="border rounded-md px-2 py-1 cursor-pointer"
        :class="showOpenOnly ? 'bg-green-500 text-white' : ''"
        @click="showOpenOnly = !showOpenOnly"
      >
        Now open
      </div>
    </div>
    <div
      class="mx-3 py-3 text-panel-primary-text text-sm font-medium border-y border-panel-hr-border"
    >
      <span class="font-bold text-panel-accent-text">{{
        pending === false ? resultItems && resultItems.length : 'loading'
      }}</span>
      <span v-if="resultItems && resultItems.length === 1"> Entry</span>
      <span v-else> Entries</span>
      <span class="font-normal">&nbsp;&middot; On current map section</span>
    </div>
    <div v-if="resultItems && resultItems.length > 0">
      <div v-for="result in resultItems" :key="result.id">
        <ListItemCard :item-result-or-item="result" :show-oh="true" />
      </div>
    </div>
    <div v-else-if="pending === true" class="p-3">Loading...</div>
    <div v-else class="p-3">
      Nothing found.
      <div class="text-panel-primary-text pt-1 text-sm">
        Zoom further out to discover more.
      </div>
    </div>
  </div>
</template>
<script setup>
import { useGesture } from '@vueuse/gesture'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useShare,
  useWindowSize,
} from '@vueuse/core'
import { vScroll } from '@vueuse/components'

const { $log, $metric } = useNuxtApp()
const { locale } = useI18n()

const showOpenOnly = ref(false)

const searchStore = useSearchStore()
const { selectedLocalHighlight, selectedHighlightId } = storeToRefs(searchStore)

const mapStore = useMapStore()
const { mapFullSwLngLat, mapFullNeLngLat, mapVisibleBounds } =
  storeToRefs(mapStore)

const itemStore = useItemStore()

const { haversineDistance } = useMap()

const supabase = useSupabaseClient()

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const controllerStore = useControllerStore()
const {
  searchResultSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const searchResultMoveHandle = ref()
const height = ref(0)
const searchResultSheet = ref()

const { height: windowHeight } = useWindowSize()

onMounted(() => {
  $log('components:SearchResult:onMounted')
  if (searchResultSheetPosition.value === 0) {
    searchResultSheetPosition.value = snapMiddlePosition.value
  }
  height.value = windowHeight.value - searchResultSheetPosition.value
})

const selectedHighlight = computed(() => {
  return searchStore.selectedHighlight
})

const { pending, data: items } = await useAsyncData(
  `items-by-tags-box:${selectedHighlight.value.tagIds}:${mapFullSwLngLat.value.lng}:${mapFullSwLngLat.value.lat}:${mapFullNeLngLat.value.lng}:${mapFullNeLngLat.value.lat}`,
  async () => {
    const { data } = await supabase.rpc('items_by_tags_box', {
      tags_params: selectedHighlight.value.tagIds,
      min_lon: mapVisibleBounds.value.getWest(), // mapFullSwLngLat.value.lng,
      min_lat: mapVisibleBounds.value.getSouth(), // mapFullSwLngLat.value.lat,
      max_lon: mapVisibleBounds.value.getEast(), // mapFullNeLngLat.value.lng,
      max_lat: mapVisibleBounds.value.getNorth(), // mapFullNeLngLat.value.lat,
    })
    return data
  },
  {
    lazy: true,
    watch: [mapFullSwLngLat, mapFullNeLngLat, selectedHighlight],
  }
)

const { getOpeningHoursState } = useOpeningHours()

// compute items by opening hours
const resultItems = computed(() => {
  if (!items.value || (items.value && items.value.length === 0)) {
    itemStore.setHighlightedItemIds([])
    return []
  }
  const filteredItems = items.value.filter((item) => {
    if (showOpenOnly.value === true) {
      const ohState = getOpeningHoursState(item, 'hours')
      $log('components:SearchResult:resultItems:ohState', ohState === true)
      return ohState === true
    } else {
      return true
    }
  })
  itemStore.mergeDataIntoItems(filteredItems)
  const itemIds = filteredItems.map((item) => item.id)
  $log('components:SearchResult:resultItems:itemIds', itemIds)
  itemStore.setHighlightedItemIds(itemIds)

  $log('components:SearchResult:resultItems:mapCenter', [
    mapVisibleBounds.value.getCenter().lat,
    mapVisibleBounds.value.getCenter().lng,
  ])

  // add calculated distance to every item
  filteredItems.forEach((item) => {
    const distance = haversineDistance(
      [
        mapVisibleBounds.value.getCenter().lat,
        mapVisibleBounds.value.getCenter().lng,
      ],
      [item.lat, item.lon]
    )
    $log('components:SearchResult:resultItems:item', [item.lat, item.lon])
    item.distance = distance
  })
  // sort by distance
  filteredItems.sort((a, b) => a.distance - b.distance)
  $log('components:SearchResult:resultItems:filteredItems', filteredItems)
  return filteredItems
})

const highlight = computed(() => {
  return selectedLocalHighlight.value(locale.value)
})

const mediumUrl = null

const { closeSearchResult } = useController()

const close = async () => {
  await closeSearchResult()
  itemStore.setHighlightedItemIds([])
}

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    searchResultScrollY: y,
  }
  controllerStore.addDevInfo(newDevInfo)
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: searchResultMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

const isDragging = ref(false)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe, tap }) {
  height.value =
    windowHeight.value - searchResultSheetPosition.value - useRound(y).value
  isDragging.value = dragging

  const close = findClosestSnapPoint(
    searchResultSheetPosition.value + useRound(y).value
  )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      searchResultSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next
  }
  if (!dragging) {
    searchResultSheetPosition.value = close
    height.value = windowHeight.value - close
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

watch(
  selectedHighlightId,
  () => {
    if (selectedHighlightId.value) {
      $metric({ key: 'PAGE_VIEW' })
    }
  },
  { immediate: true }
)
</script>
