export const useController = () => {
  const localePath = useLocalePath()

  const closeFeedResult = async () => {
    await navigateTo(localePath({ name: 'map' }))
  }

  const closeSearchResult = async () => {
    await navigateTo(localePath({ name: 'map' }))
  }

  const closeSelectedItem = async () => {
    await navigateTo(localePath({ name: 'map' }))
  }

  const closeSelectedList = () => {
    return navigateTo(localePath({ name: 'map' }))
  }

  return {
    closeFeedResult,
    closeSearchResult,
    closeSelectedItem,
    closeSelectedList,
  }
}
