<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="map-pin" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start pt-[14px] w-full text-panel-primary-text"
    >
      <div>{{ formattedAddress }}</div>
      <div class="grid grid-cols-1 divide-y w-full pt-2 divide-panel-hr-border">
        <!-- Tags with related areas like Neighbourhoods, ... -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAddressRecipe } from '~/composables/useAddressRecipe'

const props = defineProps({
  address: {
    type: Array,
    default() {
      return []
    },
  },
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const address = computed(() => props.address)

const { formatAddress } = useAddressRecipe()

const formattedAddress = computed(() => {
  return formatAddress(address.value)
})
</script>
