// Composable for all user profiles

export const useProfile = () => {
  const getAvatarDownloadLink = async (avatarUrlMayBeRef) => {
    const avatarUrl = unref(avatarUrlMayBeRef)
    const client = useSupabaseClient()
    try {
      if (!avatarUrl) return
      const { data, error } = await client.storage
        .from('avatars')
        .download(avatarUrl)
      if (error) throw error
      const url = URL.createObjectURL(data)
      return url
    } catch (error) {
      // console.error('Error downloading image: ', error.message)
    }
  }

  return {
    getAvatarDownloadLink,
  }
}
