<template>
  <div class="fixed bottom-0">
    <MapBase />
    <MapContent />
    <slot></slot>
    <MapSidePanel />
    <BottomSheetList
      v-if="
        controllerStore.bottomSheetListOpen &&
        !controllerStore.bottomSheetListCreateOpen
      "
    />
    <BottomSheetListCreate v-if="controllerStore.bottomSheetListCreateOpen" />
    <BottomSheetListEdit v-if="controllerStore.bottomSheetListEditOpen" />
    <BottomSheetMention v-if="controllerStore.bottomSheetMentionOpen" />
    <BottomSheetPlaceCreate v-if="controllerStore.bottomSheetPlaceCreateOpen" />
    <BottomSheetPlaceCreateQuo
      v-if="controllerStore.bottomSheetPlaceCreateQuoOpen"
    />
    <BottomSheetMapStyle v-if="controllerStore.bottomSheetMapStyleOpen" />
  </div>
</template>
<script setup>
import { useScreenSafeArea, useWindowSize } from '@vueuse/core'
import { useFeedStore } from '~/stores/feed'

onMounted(() => {
  $log('layouts:map:onMounted')
  // updateDevInfo()
})

onUpdated(() => {
  $log('layouts:map:onUpdated')
})

useHead({
  bodyAttrs: {
    class: 'h-screen overflow-hidden',
    style:
      'margin: 0px; padding: 0px; width: 100%; height: 100vh; position: relative; overflow: hidden;',
  },
})
const { $log } = useNuxtApp()

$log('layouts:map:setup:begin')

const { width, height } = useWindowSize()
const controllerStore = useControllerStore()
const { top, right, bottom, left, update } = useScreenSafeArea()
const iconStore = useIconStore()
const tagStore = useTagStore()
const detailKeyStore = useDetailKeyStore()
const likeStore = useLikeStore()
const listStore = useListStore()
const mentionStore = useMentionStore()
const checkinStore = useCheckinStore()
const feedStore = useFeedStore()
const segmentStore = useSegmentStore()
const geoEntityStore = useGeoEntityStore()

// useUserProfile()

const updateDevInfo = () => {
  if (process.client) {
    const sat = getComputedStyle(document.documentElement).getPropertyValue(
      '--sat'
    )
    const sar = getComputedStyle(document.documentElement).getPropertyValue(
      '--sar'
    )
    const sab = getComputedStyle(document.documentElement).getPropertyValue(
      '--sab'
    )
    const sal = getComputedStyle(document.documentElement).getPropertyValue(
      '--sal'
    )
    update()
    const newDevInfo = {
      width: width.value,
      height: height.value,
      legacyScreenSafeArea: { sat, sar, sab, sal },
      screenSafeArea: { top, right, bottom, left },
    }
    $log('layouts:map:updateDevInfo:newDevInfo', newDevInfo)
    controllerStore.addDevInfo(newDevInfo)
  }
}

const { pending } = await useAsyncData(
  'icons-tags-detail-keys-likes-mentions-checkins-featured-segments-geo-entities',
  async () => {
    const [
      icons,
      tags,
      detailKeys,
      likes,
      mentions,
      checkins,
      featured,
      segments,
      geoEntities,
    ] = await Promise.all([
      iconStore.fetchIcons(),
      tagStore.fetchTags(),
      detailKeyStore.fetchDetailKeys(),
      likeStore.fetchLikes(),
      mentionStore.fetchMentions(),
      checkinStore.fetchCheckins(),
      feedStore.fetchFeatured(),
      segmentStore.fetchSegments(),
      geoEntityStore.fetchGeoEntities(),
    ])
    return {
      icons,
      tags,
      detailKeys,
      likes,
      mentions,
      checkins,
      featured,
      segments,
      geoEntities,
    }
  }
)

const supabaseUser = useSupabaseUser()

watch(
  supabaseUser,
  async (newUser) => {
    if (newUser) {
      $log('MapSidePanel:watch:watch:user')
      await listStore.init()
    }
  },
  { immediate: true }
)

watch(
  pending,
  () => {
    $log('layouts:map:pending', pending.value)
  },
  { immediate: true }
)

watch(
  width,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)
watch(
  height,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)

watch(
  top,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)

watch(
  right,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)

watch(
  bottom,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)

watch(
  left,
  () => {
    updateDevInfo()
  },
  { immediate: true }
)

$log('layouts:map:setup:end')
</script>
<style>
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}
</style>
