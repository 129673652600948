<template>
  <div
    ref="listResultsheet"
    v-scroll="onScroll"
    class="mx-2 sm:mx-4 bg-panel-background rounded-t-2xl overflow-hidden overflow-y-auto pointer-events-auto sm:h-screen"
    :style="xs ? 'height: ' + height + 'px;' : ''"
  >
    <div class="sticky top-0">
      <div class="relative bg-panel-background/90">
        <img
          v-if="mediumUrl"
          :src="mediumUrl"
          alt=""
          class="w-full h-48 object-cover"
        />
        <div v-else class="h-10">&nbsp;</div>
        <div
          ref="listResultMoveHandle"
          class="absolute w-full h-full top-0"
        ></div>
        <div
          class="absolute w-full h-full top-0 grid grid-cols-1 gap-4 content-between pointer-events-none p-1"
        >
          <div class="flex flex-row justify-between">
            <div class="p-2 pointer-events-auto">
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  ' w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="backToSideMenu()"
              >
                <SvgIcon
                  name="chevron-left"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
            <div class="p-2">
              <div
                :class="[
                  mediumUrl
                    ? 'border-panel-nav-control-background-medium'
                    : 'border-panel-nav-control-background',
                  'w-12 h-0 border-2  bg-opacity-60 rounded sm:hidden',
                ]"
              >
                &nbsp;
              </div>
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
                @click="close()"
              >
                <SvgIcon
                  name="x-mark"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
          <div v-if="mediumUrl" class="flex flex-row justify-between">
            <div class="p-2">
              <span
                v-if="showTitleOverImage"
                class="text-white text-2xl font-bold drop-shadow-[0_1.4px_1.4px_rgba(0,0,0,0.8)]"
                >{{ listTitle }}</span
              >
            </div>
            <div class="p-2">
              <button
                type="button"
                :class="[
                  mediumUrl
                    ? 'bg-panel-nav-control-background-medium'
                    : 'bg-panel-nav-control-background',
                  'w-8 h-8 rounded-lg bg-opacity-60 pointer-events-auto',
                ]"
              >
                <SvgIcon
                  name="photo"
                  :class="[
                    mediumUrl
                      ? 'stroke-panel-nav-control-action-symbol-medium'
                      : 'stroke-panel-nav-control-action-symbol',
                    'h-5 w-5 my-auto mx-auto',
                  ]"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!mediumUrl"
          class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
        >
          {{ listTitle }}
        </div>
      </div>
    </div>
    <div
      v-if="mediumUrl"
      class="text-panel-accent-text text-2xl font-semibold px-3 pt-4 pb-3"
    >
      {{ listTitle }}
    </div>

    <div
      class="px-3 pb-3 text-panel-primary-text text-sm font-normal flex flex-row items-center"
    >
      <div class="flex-auto">
        {{ subTitle }}
      </div>
      <div
        v-if="true"
        class="p-2 ring-1 ring-inset ring-panel-control-border bg-panel-control-background rounded cursor-pointer"
        @click="controllerStore.openBottomSheetListEdit(selectedList)"
      >
        <SvgIcon
          name="ellipsis-horizontal"
          type="solid"
          class="h-4 w-4 stroke-panel-accent-text"
        />
      </div>
    </div>
    <div
      v-if="nonZeroStats.length > 0"
      class="mx-3 py-3 text-panel-primary-text text-sm font-medium border-y border-panel-hr-border"
    >
      <span v-for="(stat, index) in nonZeroStats" :key="index">
        <span v-if="index > 0"> &middot; </span>
        <span class="font-bold text-panel-accent-text">{{ stat.count }}</span>
        {{ stat.name }}
      </span>
    </div>
    <div class="mx-3 py-3 text-panel-primary-text text-sm font-medium">
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
            class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-panel-control-background px-3 py-2 text-xs font-semibold text-panel-accent-text shadow-sm ring-1 ring-inset ring-panel-control-border"
          >
            Sort
            <SvgIcon
              name="chevron-down"
              class="h-4 w-4 stroke-panel-control-symbol"
            />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem
                v-for="(sorting, index) in sortings"
                :key="index"
                v-slot="{ active }"
              >
                <div
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block flex items-center px-4 py-2 text-sm cursor-pointer',
                  ]"
                  @click="setSorting(sorting.value)"
                >
                  <span class="flex-auto">{{
                    $t('lists.sortings.' + sorting.translation)
                  }}</span>
                  <SvgIcon
                    v-if="sorting.value === currentSorting"
                    name="check-circle"
                    class="h-4 w-4 mr-2"
                  />
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <!-- Result List -->
    <div v-for="(result, index) in listResults" :key="index">
      <ListItemCard :item-result-or-item="result" :show-oh="true" />
    </div>
    <!-- Footer -->
    <div class="px-3 py-8">
      <div class="text-sm text-center text-slate-400">&copy; Quo space</div>
    </div>
  </div>
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useGesture } from '@vueuse/gesture'
// import { useMotionProperties, useSpring } from '@vueuse/motion'
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useScrollLock,
  useWindowSize,
} from '@vueuse/core'
import { vScroll } from '@vueuse/components'

const { $log } = useNuxtApp()

onUpdated(() => {
  $log('components:ListResult:onUpdated')
})

onUnmounted(() => {
  $log('components:ListResult:onUnmounted')
})

const { t } = useI18n()

const breakpoints = useBreakpoints(breakpointsTailwind)
const xs = breakpoints.smaller('sm')

const controllerStore = useControllerStore()
const {
  listResultSheetPosition,
  snapBottomPosition,
  snapMiddlePosition,
  snapTopPosition,
} = storeToRefs(controllerStore)

const listStore = useListStore()
const { selectedList, selectedListId } = storeToRefs(listStore)
const sortings = computed(() => listStore.sortings)

const { closeSelectedList } = useController()

const { getListResult, getListSubTitle, getListTitle, getMediumUrl } = useList()
const listTitle = computed(() => {
  if (!selectedListId.value) return ''
  return getListTitle(selectedList.value.name, selectedList.value.purpose)
})
const mediumUrl = getMediumUrl(selectedList.value.media[0])
const subTitle = computed(() => getListSubTitle(selectedList.value))
$log('components:ListResult:mediumUrl', mediumUrl)

const itemStore = useItemStore()

const currentSorting = computed(() => {
  if (!selectedList.value) return ''
  return selectedList.value.sorting
})

const listResults = ref([])

const getListResults = async () => {
  if (!selectedList.value) return
  const newResults = await getListResult(
    selectedList.value,
    currentSorting.value
  )
  listResults.value = newResults.value
  const itemIds = listResults.value.map((result) => result.id)
  itemStore.setHighlightedItemIds(itemIds)
}

watch(
  () => selectedList.value,
  async (newVal) => {
    await getListResults()
  },
  { immediate: true, deep: true }
)

const stats = computed(() => {
  const count = ref(0)
  if (listResults.value) {
    count.value = listResults.value.length
  } else {
    count.value = 0
  }

  const newStats = []
  newStats.push({
    name: t('lists.entries', count.value),
    count: count.value,
  })
  return newStats
})

const nonZeroStats = computed(() => {
  return stats.value.filter((stat) => stat.count > 0)
})

const setSorting = async (sorting) => {
  $og('components:ListResult:setSorting:sorting', sorting)
  if (!sorting) return
  if (sorting === currentSorting.value) return
  await listStore.updateListProperty(selectedList.value.id, 'sorting', sorting)
}

const listResultMoveHandle = ref()
const height = ref(0)
const listResultsheet = ref()
const showTitleOverImage = ref(false)

const { height: windowHeight } = useWindowSize()

const isLocked = useScrollLock(listResultsheet)

onMounted(() => {
  $log('components:ListResult:onMounted')
  $log(
    'components:ListResult:listResultSheetPosition',
    listResultSheetPosition.value
  )
  if (listResultSheetPosition.value === 0) {
    controllerStore.setListResultSheetPosition(snapMiddlePosition.value)
  }
  height.value = windowHeight.value - snapMiddlePosition.value - 80
})

function onScroll(state) {
  const { y } = state
  const newDevInfo = {
    scrollYListResult: y,
  }
  controllerStore.addDevInfo(newDevInfo)
  if (y.value > 28) {
    showTitleOverImage.value = true
  } else {
    showTitleOverImage.value = false
  }
}

const gestureModule = useGesture(
  {
    onDrag: handleSurfaceDrag,
  },
  {
    domTarget: listResultMoveHandle,
    eventOptions: { passive: true },
    drag: {
      filterTaps: true,
    },
  }
)

function handleSurfaceDrag({ movement: [x, y], dragging, swipe, tap }) {
  height.value =
    windowHeight.value - listResultSheetPosition.value - useRound(y).value - 80
  isLocked.value = dragging
  const offset = listResultsheet.value.getBoundingClientRect()
  const close = findClosestSnapPoint(
    listResultSheetPosition.value + useRound(y).value
  )

  if (swipe[1] !== 0) {
    const next = findNextSnapPoint(
      listResultSheetPosition.value + useRound(y).value,
      swipe[1]
    )
    height.value = windowHeight.value - next - 80
  }
  if (!dragging) {
    listResultSheetPosition.value = close
    height.value = windowHeight.value - close - 80
    return
  }

  // update initial point for next drag
  gestureModule.config.drag.initial = [x, 0]
}

// find closest snap point
const findClosestSnapPoint = (currentYPosition) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const closest = positions.reduce(function (prev, curr) {
    return Math.abs(curr - currentYPosition) < Math.abs(prev - currentYPosition)
      ? curr
      : prev
  })

  return closest
}

// find next snap point
const findNextSnapPoint = (currentYPosition, swipeDirection) => {
  const positions = []
  positions.push(snapTopPosition.value)
  positions.push(snapMiddlePosition.value)
  positions.push(snapBottomPosition.value)

  const next = positions.reduce(function (prev, curr) {
    if (swipeDirection === -1) {
      return curr < currentYPosition ? curr : prev
    } else {
      return curr > currentYPosition ? curr : prev
    }
  })

  return next
}

const close = () => {
  closeSelectedList()
  itemStore.setHighlightedItemIds([])
}

const backToSideMenu = () => {
  close()
  controllerStore.openMapSidePanel()
}
</script>
