export const useMapStore = defineStore('map', {
  state: () => ({
    availableStyles: [
      {
        id: 5,
        name: 'Standard 3D',
        light: {
          styleUrl: null,
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: 'dawn',
        },
        dark: {
          styleUrl: null,
          textColor: '#ffffff',
          haloColor: '#000000',
          lightPreset: 'dusk',
        },
        useMapboxStandardStyle: true,
        previewImage: 'standard-light.png',
      },
      {
        id: 3,
        name: 'Satellite',
        light: {
          styleUrl: 'mapbox://styles/mritter/clmvlanpj02o601pjejv1b3t4',
          textColor: '#ffffff',
          haloColor: '#000000',
          lightPreset: null,
        },
        dark: {
          styleUrl: 'mapbox://styles/mritter/clmvlanpj02o601pjejv1b3t4',
          textColor: '#ffffff',
          haloColor: '#000000',
          lightPreset: null,
        },
        useMapboxStandardStyle: false,
        previewImage: 'satellite.png',
      },
      {
        id: 4,
        name: 'Outdoors',
        light: {
          styleUrl: 'mapbox://styles/mritter/clmvlti2c02p301pf6f82f69x',
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: null,
        },
        dark: {
          styleUrl: 'mapbox://styles/mritter/clmvlti2c02p301pf6f82f69x',
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: null,
        },
        useMapboxStandardStyle: false,
        previewImage: 'outdoor.png',
      },
      {
        id: 2,
        name: 'Streets',
        light: {
          styleUrl: 'mapbox://styles/mritter/clmvm0ba202m001nz5q60ando',
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: null,
        },
        dark: {
          styleUrl: 'mapbox://styles/mritter/clmvm3tmm02nb01pb6pgy7gik',
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: null,
        },
        useMapboxStandardStyle: false,
        previewImage: 'streets.png',
      },
      {
        id: 1,
        name: 'Monochrome',
        light: {
          styleUrl: 'mapbox://styles/mritter/clmvkthxd02ly01nz9pl9dn8t',
          textColor: '#000000',
          haloColor: '#ffffff',
          lightPreset: null,
        },
        dark: {
          styleUrl: 'mapbox://styles/mritter/clmvl4o1902mm01qu10124dmr',
          textColor: '#ffffff',
          haloColor: '#000000',
          lightPreset: null,
        },
        useMapboxStandardStyle: false,
        previewImage: 'map-1.png',
      },
    ],
    mapFullSwLngLat: {},
    mapFullNeLngLat: {},
    mapVisibleBounds: {},
    selectedStyleId: 5,
    center: null,
    zoom: null,
    pitch: null,
    bearing: null,
    reconstructMap: false,
  }),
  actions: {
    getSelectedStyle(styleId) {
      const style = this.availableStyles.find((style) => style.id === styleId)
      if (style) {
        return style
      } else {
        return this.availableStyles[0]
      }
    },
    setSelectedStyleId(styleId) {
      this.selectedStyleId = styleId
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
}
