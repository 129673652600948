<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="clock" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start pt-2.5 w-full text-panel-primary-text"
    >
      <div>
        <span
          class="inline-flex items-center rounded-full px-2 py-1 text-sm font-medium ring-1"
          :class="
            state === 'open'
              ? 'text-panel-oh-open-text ring-panel-oh-open-border bg-panel-oh-open-background'
              : 'ring-panel-primary-text'
          "
          >{{ openClosedText }}</span
        >
        <span v-if="changeShort !== null"> &middot; {{ changeShort }}</span>
      </div>
      <div class="grid grid-cols-1 divide-y w-full pt-2 divide-panel-hr-border">
        <div v-for="i in ohDayCount" :key="i" class="flex flex-row py-2">
          <div class="w-28 flex-shrink-0">
            {{ distanceOrWeekdayFromNowByDays(i - 1, locale) }}
          </div>
          <div class="whitespace-pre-line">
            {{ getInterval(dateFromNowByDays(i - 1)) }}
          </div>
        </div>
        <div class="py-2 text-xs">
          <span
            class="underline cursor-pointer"
            @click="ohDayCount = ohDayCount + 3"
            >{{ $t('openingHours.showMore') }}</span
          >
          <span v-if="ohDayCount > 3">
            &middot;
            <span class="underline cursor-pointer" @click="ohDayCount = 3">{{
              $t('openingHours.clear')
            }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  details: {
    type: Array,
    default() {
      return []
    },
  },
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const { locale, t } = useI18n()

const detail = computed(() => props.details[0])
const item = computed(() => props.item)
const ohDayCount = ref(3)

const {
  getChangeShort,
  getOpenClosedShortWithState,
  getOpeningHoursIntervals,
} = useOpeningHours()
const { dateFromNowByDays, distanceOrWeekdayFromNowByDays } = useDateTime()

const openClosedShortWithState = computed(() =>
  getOpenClosedShortWithState(item.value, 'hours')
)
const openClosedText = computed(() => openClosedShortWithState.value.text)
const state = computed(() => openClosedShortWithState.value.state)

const changeShort = computed(() => getChangeShort(item.value, 'hours'))

const getInterval = (date) => {
  return getOpeningHoursIntervals(
    date,
    item.value,
    detail.value.value,
    t('openingHours.closed'),
    locale
  )
}
</script>
