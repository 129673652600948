export const useDetailKeyStore = defineStore('detailKey', {
  state: () => ({
    detailsKeys: [],
  }),
  actions: {
    async fetchDetailKeys() {
      const supabase = useSupabaseClient()

      const { data } = await supabase
        .from('details_keys')
        .select(
          'key, iconId:icon_id, iconType:icon_type, color, bgColor:bg_color, detailsKeysTranslations:details_keys_translations(key,locales(code))'
        )
      console.log('store:detailKey: fetchDetailKeys: data: ', data.length)
      if (data) {
        this.detailsKeys = data
      }
    },
    getDetailKeyByKey(key) {
      const detailKey = this.detailsKeys.find(
        (detailKey) => detailKey.key === key
      )
      if (detailKey) {
        return detailKey
      } else {
        return null
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDetailKeyStore, import.meta.hot))
}
