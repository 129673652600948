<template>
  <div class="fixed z-50 h-screen w-full top-0 bg-panel-background-behind/70">
    <div
      class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto p-4"
    >
      <div class="flex flex-col">
        <div class="flex justify-between items-center mb-3">
          <div class="text-xl font-medium text-panel-accent-text">
            {{ title }}
          </div>
          <div class="cursor-pointer" @click="cancel()">
            <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
          </div>
        </div>
        <div class="mx-auto space-y-4">
          <div class="flex flex-col">
            <div class="relative flex items-center w-80 rounded-full">
              {{ question }}
            </div>
          </div>
          <div class="flex flex-row space-x-4 w-80">
            <button
              class="flex justify-center flex-auto rounded-full border border-pink-400 py-2 cursor-pointer text-pink-400"
              @click="cancel()"
            >
              {{ cancelButton }}
            </button>
            <button
              class="flex justify-center flex-auto rounded-full bg-panel-action text-white py-2 cursor-pointer"
              @click="confirm()"
            >
              {{ confirmButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
defineProps({
  title: {
    type: String,
    required: true,
  },
  question: {
    type: String,
    required: true,
  },
  cancelButton: {
    type: String,
    required: true,
  },
  confirmButton: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['confirmed'])

const cancel = () => {
  emit('confirmed', false)
}

const confirm = () => {
  emit('confirmed', true)
}
</script>
