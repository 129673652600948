import { parseISO } from 'date-fns'
import { useSorted } from '@vueuse/core'

export const useList = () => {
  const { $log } = useNuxtApp()

  const getListResult = async (
    listMayBeRef,
    sortingMayBeRef,
    localeMayBeRef
  ) => {
    const list = unref(listMayBeRef)
    const sorting = unref(sortingMayBeRef)
    const locale = unref(localeMayBeRef)
    $log('composables:useList:getListResult:list', list)
    $log('composables:useList:getListResult:sorting', sorting)
    if (!list || (list && !list.items)) return null
    $log('composables:useList:getListResult:check list:list', list)
    const purpose = list.purpose
    const listResults = ref([])
    const itemStore = useItemStore()
    const user = useSupabaseUser()
    const { getItemTitle } = useItem()
    if (purpose === 'likes') {
      const likeStore = useLikeStore()
      const { likes } = storeToRefs(likeStore)
      $log('composables/useList.js:getListResult: likes: ', likes)
      for (const like of likes.value) {
        const item = await itemStore.getOrFetchItemById(like.itemId)
        if (item) {
          const listItem = Object.assign({}, item)
          listItem.type = 'likes'
          listItem.insertedAt = parseISO(like.insertedAt)
          listItem.isUnlike = like.isUnlike
          listResults.value.push(listItem)
        }
      }
      let sortedListResults = null
      if (sorting === 'inserted_at_asc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => a.insertedAt - b.insertedAt
        )
      } else if (sorting === 'inserted_at_desc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => b.insertedAt - a.insertedAt
        )
      } else if (sorting === 'name_asc') {
        sortedListResults = useSorted(listResults, (a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      $log(
        'composables/useList.js:getListResult: likes: sortedListResults: ',
        sortedListResults
      )
      return sortedListResults
    } else if (purpose === 'mentions') {
      const mentionStore = useMentionStore()
      const mentions = mentionStore.getMentionsByUserId(user.value.id)
      $log('composables/useList.js:getListResult: mentions: ', mentions)
      for (const mention of mentions) {
        const item = await itemStore.getOrFetchItemById(mention.itemId)
        if (item) {
          // Because we will use the same item for multiple mentions, we need to create a new object
          const newItem = Object.assign({}, item)
          newItem.type = 'mentions'
          newItem.insertedAt = parseISO(mention.insertedAt)
          newItem.bricks = mention.bricks
          listResults.value.push(newItem)
        }
      }
      let sortedListResults = null
      if (sorting === 'inserted_at_asc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => a.insertedAt - b.insertedAt
        )
      } else if (sorting === 'inserted_at_desc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => b.insertedAt - a.insertedAt
        )
      } else if (sorting === 'name_asc') {
        sortedListResults = useSorted(listResults, (a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      $log(
        'composables/useList.js:getListResult: mentions: sortedListResults: ',
        sortedListResults
      )
      return sortedListResults
    } else if (purpose === 'checkins') {
      const checkinStore = useCheckinStore()
      const { checkins } = storeToRefs(checkinStore)
      $log('composables:useList:getListResult:checkins', checkins)
      for (const checkin of checkins.value) {
        const item = await itemStore.getOrFetchItemById(checkin.itemId)
        if (item) {
          const listItem = Object.assign({}, item)
          listItem.type = 'checkins'
          listItem.insertedAt = parseISO(checkin.insertedAt)
          listResults.value.push(listItem)
        }
      }
      let sortedListResults = null
      if (sorting === 'inserted_at_asc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => a.insertedAt - b.insertedAt
        )
      } else if (sorting === 'inserted_at_desc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => b.insertedAt - a.insertedAt
        )
      } else if (sorting === 'name_asc') {
        sortedListResults = useSorted(listResults, (a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      $log(
        'composables:useList:getListResult:checkins:sortedListResults',
        sortedListResults
      )
      return sortedListResults
    } else {
      // parallel or sequential? https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
      for (const listsItem of list.items) {
        const item = await itemStore.getOrFetchItemByNanoId(
          listsItem.item.nanoId
        )
        $log('composables/useList.js:getListResult: item: ', item)
        if (item) {
          const newItem = Object.assign({}, item)
          newItem.sortOrder = listsItem.sortOrder
          newItem.type = 'item'
          newItem.insertedAt = parseISO(listsItem.insertedAt)
          newItem.title = getItemTitle(item, locale)
          listResults.value.push(newItem)
        }
      }
      let sortedListResults = null
      if (sorting === 'inserted_at_asc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => a.insertedAt - b.insertedAt
        )
      } else if (sorting === 'inserted_at_desc') {
        sortedListResults = useSorted(
          listResults,
          (a, b) => b.insertedAt - a.insertedAt
        )
      } else if (sorting === 'name_asc') {
        sortedListResults = useSorted(listResults, (a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      $log(
        'composables/useList.js:getListResult: else: sortedListResults: ',
        sortedListResults
      )
      return sortedListResults
    }
  }

  const getListSubTitle = (listMayBeRef) => {
    const list = unref(listMayBeRef)
    if (!list) {
      return null
    }
    const { t } = useI18n()
    const purpose = list.purpose
    const translation = t(`lists.defaultTitles.purposes.${purpose}`)
    return translation
  }

  const getListTitle = (title, purpose = null) => {
    const { t } = useI18n()
    if (title) {
      return title
    } else if (purpose) {
      const translation = t(`lists.defaultTitles.purposes.${purpose}`)
      return translation
    } else {
      return 'List'
    }
  }

  const getMediumUrl = (mediumMayBeRef) => {
    const medium = unref(mediumMayBeRef)
    if (!medium) {
      return null
    }
    const client = useSupabaseClient()
    const { data } = client.storage
      .from('public-media')
      .getPublicUrl(medium.medium.path, {
        transform: {
          width: 900,
          height: 400,
        },
      })
    return data.publicUrl
  }

  const isListAvailable = (purpose, isQuoPro) => {
    if (isQuoPro) {
      return true
    } else if (purpose === 'favorites' || purpose === 'want_to_see') {
      return true
    } else {
      return false
    }
  }

  return {
    getListResult,
    isListAvailable,
    getListSubTitle,
    getListTitle,
    getMediumUrl,
  }
}
