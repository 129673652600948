<template>
  <div class="fixed z-40 h-screen w-full top-0 bg-panel-background-behind/70">
    <div
      class="absolute bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto p-4"
    >
      <div class="flex flex-col">
        <div class="flex justify-between items-center mb-3">
          <div class="text-xl font-medium text-panel-accent-text">
            {{ $t('bottomSheetListEdit.title') }}
          </div>
          <div
            class="cursor-pointer"
            @click="controllerStore.closeBottomSheetListEdit()"
          >
            <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
          </div>
        </div>
        <Form
          class="mx-auto space-y-4"
          :initial-values="listInitialFormValues"
          @submit="onSubmit"
        >
          <div class="flex flex-col">
            <div class="ml-4 mb-1 text-panel-primary-text text-sm">Name</div>
            <div
              class="relative flex items-center bg-panel-list-pill-background w-80 rounded-full"
            >
              <Field
                name="name"
                type="text"
                class="ml-4 !outline-none text-md font-medium leading-6 py-2 text-panel-list-text bg-transparent w-full border-transparent focus:border-transparent focus:ring-0 text-md px-0"
                autocomplete="off"
                :placeholder="
                  editingList.purpose !== 'list'
                    ? getListTitle(editingList.name, editingList.purpose)
                    : ''
                "
                :rules="validateListName"
              />
            </div>
            <div class="ml-4 mt-1 text-panel-alert-message text-sm">
              <ErrorMessage name="name" />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="ml-4 mb-1 text-panel-primary-text text-sm">
              Visibility
            </div>
            <div
              class="relative flex items-center bg-panel-list-pill-background w-80 rounded-full"
            >
              <Field
                name="visibility"
                as="select"
                class="mx-4 !outline-none text-md font-medium py-2 text-panel-list-text bg-transparent w-full cursor-pointer border-transparent focus:border-transparent focus:ring-0 px-0 text-md"
              >
                <option value="private">Private</option>
                <option value="public" disabled>Public (Coming soon)</option>
              </Field>
            </div>
            <div class="ml-4 mt-1 text-panel-alert-message text-sm">
              <ErrorMessage name="visibility" />
            </div>
          </div>
          <div class="flex flex-col">
            <div class="ml-4 mb-1 text-panel-primary-text text-sm">
              Shortcut
            </div>
            <div class="relative flex items-center w-80 mb-4 mt-1">
              <SwitchGroup as="div" class="flex items-center ml-4">
                <Switch
                  v-model="pinToShortcutsBar"
                  :class="[
                    pinToShortcutsBar
                      ? 'bg-panel-action'
                      : 'bg-panel-list-pill-background',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      pinToShortcutsBar ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
                <SwitchLabel as="span" class="ml-3 text-sm cursor-pointer">
                  <span class="font-regular text-gray-900"
                    >Show List in Shortcut Bar</span
                  >
                </SwitchLabel>
              </SwitchGroup>
            </div>
          </div>
          <AlertBox
            v-if="apiError"
            theme="error"
            :message="apiError.message"
            class="mt-2"
          />
          <div class="flex flex-row space-x-4">
            <button
              class="flex justify-center w-10 rounded-full border border-pink-400 text-white py-2 cursor-pointer"
              type="button"
              @click="confirmDeleteList()"
            >
              <SvgIcon name="trash" class="w-5 h-5 stroke-pink-400" />
            </button>
            <button
              class="flex justify-center flex-auto rounded-full bg-panel-action text-white py-2 cursor-pointer"
              type="submit"
            >
              {{ $t('bottomSheetListEdit.saveListCTA') }}
            </button>
          </div>
        </Form>
      </div>
    </div>
    <DialogConfirmCancel
      v-if="deleteListDialog.show"
      :title="deleteListDialog.title"
      :question="deleteListDialog.question"
      :cancel-button="deleteListDialog.cancelButton"
      :confirm-button="deleteListDialog.confirmButton"
      @confirmed="deleteList"
    />
  </div>
</template>
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
const { $log } = useNuxtApp()
const controllerStore = useControllerStore()
const { editingList } = storeToRefs(controllerStore)

const listStore = useListStore()

const { getListTitle } = useList()

const { t } = useI18n()

const listInitialFormValues = {
  name: editingList.value.name,
  visibility: editingList.value.visibility,
}
$log(
  'components/BottomSheetListEdit.vue: listInitialFormValues: ',
  listInitialFormValues
)

const loading = ref(false)
const apiError = ref(null)
const pinToShortcutsBar = ref(false)
pinToShortcutsBar.value = editingList.value.pinToShortcutsBar

const handleCreateList = async (values) => {
  const error = await listStore.updateList(
    editingList.value.id,
    values.name,
    pinToShortcutsBar.value
  )
  $log('components/BottomSheetListCreate.vue: handleCreateList: error: ', error)
  if (error) {
    apiError.value = error
  } else {
    controllerStore.setEditingList(null)
    controllerStore.closeBottomSheetListEdit()
  }
}

const onSubmit = async (values) => {
  $log('submit: values: ', values)
  loading.value = true
  await handleCreateList(values)
  loading.value = false
}

const validateListName = (value) => {
  $log('validateListName: value: ', value)
  if (editingList.value.purpose !== 'list') {
    return true
  }
  if (!(value && value.trim())) {
    return t('bottomSheetListCreate.errors.nameRequired')
  }
  if (value && value.length > 64) {
    return t('bottomSheetListCreate.errors.nameTooLong')
  }
  return true
}

const deleteListDialog = ref({
  show: false,
  title: t('bottomSheetListEdit.deleteListDialog.title'),
  question: t('bottomSheetListEdit.deleteListDialog.question'),
  cancelButton: t('bottomSheetListEdit.deleteListDialog.cancelCTA'),
  confirmButton: t('bottomSheetListEdit.deleteListDialog.confirmCTA'),
})

const confirmDeleteList = () => {
  deleteListDialog.value.show = true
}

const deleteList = async (confirmed) => {
  $log('deleteList: confirmed: ', confirmed)
  deleteListDialog.value.show = false
  if (!confirmed) return
  const error = await listStore.deleteList(editingList.value.id)
  $log('components/BottomSheetListEdit.vue: deleteList: error: ', error)
  if (error) {
    apiError.value = error
  } else {
    controllerStore.setEditingList(null)
    controllerStore.closeBottomSheetListEdit()
  }
}
</script>
