import { useListStore } from './list'

export const useLikeStore = defineStore('like', {
  state: () => ({
    likes: [],
    likesCount: [],
  }),
  getters: {
    getLikeByItemId: (state) => {
      return (itemIdMayBeRef) => {
        const itemId = unref(itemIdMayBeRef)
        return state.likes.find((like) => like.itemId === itemId)
      }
    },
    getLikeStateByItemId: (state) => {
      return (itemIdMayBeRef) => {
        const itemId = unref(itemIdMayBeRef)
        const like = state.likes.find((like) => like.itemId === itemId)
        if (!like) return 'none'
        if (like.isUnlike) return 'unlike'
        return 'like'
      }
    },
  },
  actions: {
    async fetchLikes() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('likes')
        .select(
          'id, insertedAt:inserted_at, userId:user_id, isUnlike:is_unlike, itemId:item_id'
        )
        .order('inserted_at', { ascending: false })
      console.log('store:like: fetchLikes: data: ', data.length)
      if (data) {
        this.likes = data
      }
    },
    async toggleLike(itemIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)

      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      const listStore = useListStore()
      const { likesList } = storeToRefs(listStore)

      const like = this.getLikeByItemId(itemId)

      if (like) {
        if (like.isUnlike === false) {
          await client
            .from('likes')
            .update({ is_unlike: true })
            .eq('id', like.id)
        } else {
          await client.from('likes').delete().eq('id', like.id)
        }
        await this.fetchLikes()
      } else {
        const { data } = await client
          .from('likes')
          .insert({
            list_id: likesList.value.id,
            user_id: user.value.id,
            item_id: itemId,
          })
          .select(
            'id, insertedAt:inserted_at, userId:user_id, isUnlike:is_unlike, itemId:item_id'
          )
          .single()
        this.likes.push(data)
      }
      await this.fetchLikesCount(itemId)
    },
    async fetchLikesCount(itemIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      if (!itemId) return
      const client = useSupabaseClient()
      const { data, error } = await client.rpc('likes_count_by_item_id', {
        for_item_id: itemId,
      })
      if (error) {
        console.log('store:like:fetchLikesCount:error: ', error)
      }
      const index = this.likesCount.findIndex((item) => item.itemId === itemId)
      if (index !== -1) {
        this.likesCount[index].count = data[0]
      } else {
        this.likesCount.push({ itemId, count: data[0] })
      }
      // this.checkinsCount.push({itemId, count: data[0]})
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCheckinStore, import.meta.hot))
}
