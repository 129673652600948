<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="share" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start pt-[14px] w-full text-panel-primary-text"
    >
      <div>{{ $t('detailSegment.social.title', { name }) }}</div>
      <div class="flex flex-col w-full mt-3">
        <NuxtLink
          v-for="(detail, index) in fullDetails"
          :key="index"
          class="flex flex-row my-1 py-2 bg-panel-list-pill-background w-full text-panel-primary-text font-normal rounded-full"
          target="_blank"
          :to="detail.value"
          @click="
            $metric({
              key: 'ITEM_DETAIL_TRANSLATION_CLICK',
              itemId: item.id,
              itemDetailTranslationId: detail.id,
            })
          "
        >
          <div class="flex justify-center items-center">
            <SvgIcon
              v-if="detail.icon.id"
              :name="detail.icon.name"
              class="w-5 h-5 ml-4 mr-2"
              :class="'fill-' + detail.detailKey.bgColor"
              :type="detail.detailKey.iconType"
            />
            <div v-else class="w-5 h-5 ml-4 mr-2">&nbsp;</div>
          </div>
          <div class="flex items-center flex-auto">
            {{ detail.name }}
          </div>
          <div class="flex items-center">
            <SvgIcon
              name="chevron-right"
              class="w-4 h-4 outline-panel-primary-text stroke-2 mr-3"
              type="outline"
            />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  details: {
    type: Array,
    default() {
      return []
    },
  },
  item: {
    type: Object,
    default() {
      return {}
    },
  },
  name: {
    type: String,
    default() {
      return ''
    },
  },
})

const { $log } = useNuxtApp()

const { locale } = useI18n()

const details = computed(() => props.details)

const detailKeyStore = useDetailKeyStore()
const { getDetailKeyTranslation } = useDetails()
const iconStore = useIconStore()

const fullDetails = computed(() => {
  const newDetails = []
  for (const detail of details.value) {
    const detailKey = detailKeyStore.getDetailKeyByKey(detail.key)
    const keyTranslation = getDetailKeyTranslation(detailKey, locale.value)
    let icon = {}
    if (detailKey.iconId) {
      icon = iconStore.getIconById(detailKey.iconId)
    }
    let name = null
    if (keyTranslation) {
      name = keyTranslation.key
    }
    newDetails.push({
      ...detail,
      detailKey,
      name,
      icon,
    })
  }
  newDetails.sort((a, b) => {
    return a.name - b.name
  })
  return newDetails
})
$log('components/DetailSegmentSocial: fullDetails: ', fullDetails.value)
</script>
