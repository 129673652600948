<template>
  <div class="flex py-1.5 w-full">
    <div class="w-16 flex-none flex justify-center content-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="phone" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div class="text-sm flex items-center text-panel-action overflow-hidden">
      <NuxtLink
        class="truncate"
        :to="phoneUrl"
        @click="
          $metric({
            key: 'ITEM_DETAIL_TRANSLATION_CLICK',
            itemId: item.id,
            itemDetailTranslationId: detail.id,
          })
        "
        >{{ phoneRaw }}</NuxtLink
      >
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  details: {
    type: Array,
    default: () => [],
  },
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const detail = computed(() => props.details[0])

const phoneRaw = computed(() => detail.value.value)

const phoneUrl = computed(() => 'tel:' + phoneRaw.value)
</script>
