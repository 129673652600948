<template>
  <div class="fixed z-40 h-screen w-full top-0 bg-panel-background-behind/70">
    <div
      class="absolute max-h-[calc(100vh-32px)] bottom-4 bg-panel-background rounded-lg inset-x-2 w-auto sm:w-[640px] mx-auto p-4 overflow-y-hidden"
    >
      <div class="flex flex-col max-h-[calc(100vh-56px)]">
        <div class="flex justify-between items-center mb-3">
          <div class="text-xl font-medium text-panel-accent-text">
            {{ $t('bottomSheetList.title') }}
          </div>
          <div
            class="cursor-pointer"
            @click="controllerStore.closeBottomSheetList"
          >
            <SvgIcon name="x-mark" class="w-6 h-6 stroke-panel-action" />
          </div>
        </div>
        <div class="mx-auto space-y-4 flex-1 overflow-y-auto">
          <div
            v-for="(list, index) in addableLists"
            :key="index"
            class="relative flex items-center bg-panel-list-pill-background w-80 rounded-full cursor-pointer"
            @click="toggleItemInList(list)"
          >
            <div class="flex ml-4 mr-3">
              <SvgIcon
                :name="list.icons.name"
                class="h-6 w-6 text-panel-list-text"
              />
            </div>
            <div
              class="min-w-0 flex-1 text-md font-medium leading-6 py-2 text-panel-list-text"
            >
              {{ getListTitle(list.name, list.purpose) }}
            </div>
            <div class="flex h-6 items-center ml-2 mr-3">
              <div
                class="h-5 w-5 rounded-full flex items-center justify-center"
                :class="
                  isItemInList(list)
                    ? 'bg-panel-action'
                    : 'border border-panel-list-check-border'
                "
              >
                <SvgIcon
                  v-if="isItemInList(list)"
                  name="check"
                  type="outline"
                  class="w-3 h-3 stroke-[4px] stroke-white"
                />
              </div>
            </div>
          </div>
          <div
            v-if="isQuoPro"
            class="flex justify-center rounded-full border border-panel-accent-text text-panel-accent-text py-2 cursor-pointer"
            @click="createList()"
          >
            {{ $t('bottomSheetList.createNewListCTA') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const { $log } = useNuxtApp()
const controllerStore = useControllerStore()
const { listForItemId } = storeToRefs(controllerStore)

const itemStore = useItemStore()
const selectedItem = computed(() => itemStore.getItemById(listForItemId.value))

const profileStore = useProfileStore()
const { isQuoPro } = storeToRefs(profileStore)

const listStore = useListStore()
const { addableLists } = storeToRefs(listStore)

const { getListTitle, isListAvailable } = useList()

const toggleItemInList = (list) => {
  $log('component: BottomSheetList.vue, method: toggleItemInList, list: ', list)
  if (!isListAvailable(list.purpose, isQuoPro)) {
    return
  }
  listStore.toggleListsItem(selectedItem.value.id, list.id)
}

const isItemInList = (list) => {
  const isInList = listStore.isItemInList(selectedItem.value.id, list.id)
  $log(
    'component: BottomSheetList.vue, method: isItemInList, isInList: ',
    isInList
  )
  return isInList
}

const createList = () => {
  controllerStore.openBottomSheetListCreate()
}
</script>
