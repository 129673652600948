export const useSearchStore = defineStore('search', {
  state: () => ({
    highlights: [
      {
        id: 1,
        name: 'Eat',
        icon: 'fork-knife',
        iconType: 'outline',
        tagIds: [49, 53],
        translations: [{ locale: 'en', name: 'Eat', urlName: 'eat' }],
      },
      {
        id: 2,
        name: 'Drink',
        icon: 'glass-cocktail',
        iconType: 'solid',
        tagIds: [43, 44, 45, 46],
        translations: [{ locale: 'en', name: 'Drink', urlName: 'drink' }],
      },
      {
        id: 3,
        name: 'Sleep',
        icon: 'bed-king',
        iconType: 'solid',
        tagIds: [218, 219, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230],
        translations: [{ locale: 'en', name: 'Sleep', urlName: 'sleep' }],
      },
      {
        id: 4,
        name: 'Entertainment',
        icon: 'ticket',
        iconType: 'solid',
        tagIds: [621, 66, 67, 71, 72, 73, 74, 76, 77, 78, 80, 81],
        translations: [
          { locale: 'en', name: 'Entertainment', urlName: 'entertainment' },
        ],
      },
      {
        id: 5,
        name: 'Charging',
        icon: 'ev-station',
        iconType: 'solid',
        tagIds: [241],
        translations: [
          { locale: 'en', name: 'Charging', urlName: 'evcharging' },
        ],
      },
    ],
    searchText: null,
    selectedHighlightId: null,
  }),
  getters: {
    getLocalHighlights: (state) => {
      return (locale) => {
        if (locale) {
          return state.highlights.map((h) => ({
            ...h,
            translation: h.translations.find((t) => t.locale === locale),
          }))
        } else {
          return state.highlights.map((h) => ({
            ...h,
            translation: h.translations.find((t) => t.locale === 'en'),
          }))
        }
      }
    },
    selectedLocalHighlight: (state) => {
      return (locale) => {
        // get selected highlight and its translation
        const highlight = state.highlights.find(
          (h) => h.id === state.selectedHighlightId
        )
        if (highlight) {
          const translation = highlight.translations.find(
            (t) => t.locale === locale
          )
          if (translation) {
            return { ...highlight, translation }
          } else {
            const translation = highlight.translations.find(
              (t) => t.locale === 'en'
            )
            return { ...highlight, translation }
          }
        }
      }
    },
    selectedHighlight: (state) =>
      state.highlights.find((h) => h.id === state.selectedHighlightId),
  },
  actions: {
    setSelectedHighlightId(urlName, locale) {
      const highlight = this.highlights.find((h) =>
        h.translations.find(
          (t) => t.urlName === urlName && t.locale === locale.value
        )
      )
      if (highlight) {
        this.selectedHighlightId = highlight.id
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
