export const useControllerStore = defineStore('controller', {
  state: () => ({
    authOpen: false,
    bottomSheetListOpen: false,
    bottomSheetListCreateOpen: false,
    bottomSheetListEditOpen: false,
    bottomSheetMapStyleOpen: false,
    bottomSheetPlaceCreateOpen: false,
    bottomSheetPlaceCreateQuoOpen: false,
    bottomSheetMentionOpen: false,
    bottomViewOpen: false,
    colorModes: ['light', 'dark'],
    detailSheetPosition: 0,
    devInfo: {},
    editingList: null,
    feedResultSheetPosition: 0,
    listForItemId: null,
    listResultSheetPosition: 0,
    mapSidePanelOpen: false,
    newPlaceChosenMethod: null,
    newPlaceCreatedItemId: null,
    newPlaceLat: null,
    newPlaceLon: null,
    searchResultSheetPosition: 0,
    sideViewOpen: false,
    snapBottomPosition: null,
    snapMiddlePosition: null,
    snapTopPosition: null,
  }),
  actions: {
    addDevInfo(info) {
      this.devInfo = Object.assign({}, this.devInfo, info)
    },
    closeAuth() {
      this.authOpen = false
    },
    closeBottomSheetList() {
      this.listForItemId = null
      this.bottomSheetListOpen = false
    },
    closeBottomSheetListCreate() {
      this.bottomSheetListCreateOpen = false
    },
    closeBottomSheetListEdit() {
      this.bottomSheetListEditOpen = false
    },
    closeBottomSheetMapStyle() {
      this.bottomSheetMapStyleOpen = false
    },
    closeBottomSheetPlaceCreate(keepBottomViewOpen = false) {
      this.bottomSheetPlaceCreateOpen = false
      this.bottomViewOpen = keepBottomViewOpen
    },
    closeBottomSheetPlaceCreateQuo() {
      this.bottomSheetPlaceCreateQuoOpen = false
      this.bottomViewOpen = false
    },
    closeBottomSheetMention() {
      this.bottomSheetMentionOpen = false
    },
    closeMapSidePanel() {
      this.mapSidePanelOpen = false
    },
    openAuth() {
      this.authOpen = true
    },
    openBottomSheetList(itemIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      if (!itemId) {
        return
      }
      this.listForItemId = itemId
      this.bottomSheetListOpen = true
    },
    openBottomSheetListCreate() {
      this.bottomSheetListCreateOpen = true
    },
    openBottomSheetListEdit(list) {
      this.bottomSheetListEditOpen = true
      this.editingList = list
    },
    openBottomSheetMapStyle() {
      this.bottomSheetMapStyleOpen = true
    },
    openBottomSheetPlaceCreate() {
      this.bottomSheetPlaceCreateOpen = true
      this.bottomViewOpen = true
    },
    openBottomSheetPlaceCreateQuo() {
      this.bottomSheetPlaceCreateQuoOpen = true
      this.bottomViewOpen = true
    },
    openBottomSheetMention() {
      this.bottomSheetMentionOpen = true
    },
    openMapSidePanel() {
      this.mapSidePanelOpen = true
    },
    setEditingList(list) {
      this.editingList = list
    },
    setDetailSheetPosition(position) {
      this.detailSheetPosition = position
    },
    setFeedResultSheetPosition(position) {
      this.feedResultSheetPosition = position
    },
    setListResultSheetPosition(position) {
      this.listResultSheetPosition = position
    },
    setNewPlaceChosenMethod(method) {
      this.newPlaceChosenMethod = method
    },
    setNewPlaceCreatedItemId(itemId) {
      this.newPlaceCreatedItemId = itemId
    },
    setNewPlaceLat(lat) {
      this.newPlaceLat = lat
    },
    setNewPlaceLon(lon) {
      this.newPlaceLon = lon
    },
    setSideViewOpen(open) {
      this.sideViewOpen = open
    },
    setSnapBottomPosition(position) {
      this.snapBottomPosition = position
    },
    setSnapMiddlePosition(position) {
      this.snapMiddlePosition = position
    },
    setSnapTopPosition(position) {
      this.snapTopPosition = position
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useControllerStore, import.meta.hot))
}
