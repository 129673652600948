import { differenceInSeconds } from 'date-fns'
import { useListStore } from './list'

export const useCheckinStore = defineStore('checkin', {
  state: () => ({
    checkins: [],
    checkinsCount: [],
  }),
  getters: {
    latestCheckinByItemId: (state) => (itemId) => {
      const checkins = state.checkins.filter(
        (checkin) => checkin.itemId === itemId
      )
      if (checkins.length === 0) return null
      checkins.sort((a, b) => {
        return new Date(b.insertedAt) - new Date(a.insertedAt)
      })
      // console.log('store:checkin:latestCheckinByItemId: checkins: ', checkins)
      return checkins[0]
    },
  },
  actions: {
    async addCheckin(itemIdMayBeRef) {
      // console.log(
      //   'store: checkin: addCheckin: itemIdMayBeRef: ',
      //   itemIdMayBeRef
      // )
      const itemId = unref(itemIdMayBeRef)

      const client = useSupabaseClient()
      const user = useSupabaseUser()
      const listStore = useListStore()
      const { checkinsList } = storeToRefs(listStore)
      // console.log('store:checkin:addCheckin: checkinsList: ', checkinsList)

      const lastCheckin = this.latestCheckinByItemId(itemId)
      if (lastCheckin) {
        const lastCheckinDate = new Date(lastCheckin.insertedAt)
        const now = new Date()
        const diff = differenceInSeconds(now, lastCheckinDate)
        // console.log('store:checkin:addCheckin: diff: ', diff)
        if (diff < 60) {
          // console.log('store:checkin:addCheckin: diff < 60: return')
          return
        }
      }

      if (!user.value) return
      const { data } = await client
        .from('checkins')
        .upsert({
          list_id: checkinsList.value.id,
          user_id: user.value.id,
          item_id: itemId,
        })
        .select('id, userId:user_id, itemId:item_id, insertedAt:inserted_at')
        .single()

      // this.checkins.push(data)
      // console.log('store:checkin:addCheckin: data: ', data)
      await this.fetchCheckins()
      await this.fetchCheckinsCount(itemId)
    },
    async fetchCheckins() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('checkins')
        .select('id, userId:user_id, itemId:item_id, insertedAt:inserted_at')
      console.log('store:checkin: fetchCheckins: data: ', data.length)
      if (data) {
        this.checkins = data
      }
    },
    async fetchCheckinsCount(itemIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      if (!itemId) return
      const client = useSupabaseClient()
      const { data, error } = await client.rpc('checkins_count_by_item_id', {
        for_item_id: itemId,
      })
      if (error) {
        console.log('store:item:fetchCheckinsCount:error: ', error)
      }
      const index = this.checkinsCount.findIndex(
        (item) => item.itemId === itemId
      )
      if (index !== -1) {
        this.checkinsCount[index].count = data[0]
      } else {
        this.checkinsCount.push({ itemId, count: data[0] })
      }
      // this.checkinsCount.push({itemId, count: data[0]})
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCheckinStore, import.meta.hot))
}
