<template>
  <div class="flex py-1.5">
    <div class="w-16 flex-none flex justify-center self-start">
      <span
        class="h-12 w-12 overflow-hidden rounded-full bg-transparent flex justify-center items-center"
      >
        <SvgIcon name="information-circle" class="w-6 h-6 stroke-slate-500" />
      </span>
    </div>
    <div
      class="text-sm flex flex-col items-start w-full text-panel-primary-text"
    >
      <div
        class="grid grid-cols-1 divide-y w-full pt-1.5 divide-panel-hr-border"
      >
        <div
          v-for="detail in fullDetails"
          :key="detail.id"
          class="flex flex-row py-2"
        >
          <div class="w-28 flex-shrink-0">{{ detail.name }}</div>
          <div class="whitespace-pre-line">
            {{ detail.value
            }}<span v-if="detail.unit">&nbsp;{{ detail.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  details: {
    type: Array,
    default() {
      return []
    },
  },
  item: {
    type: Object,
    default() {
      return {}
    },
  },
})

const { locale, t } = useI18n()
const detailKeyStore = useDetailKeyStore()
const { getDetailKeyTranslation } = useDetails()

const details = computed(() => props.details)
const item = computed(() => props.item)

const fullDetails = computed(() => {
  return details.value.map((detail) => {
    const detailKey = detailKeyStore.getDetailKeyByKey(detail.key)
    const keyTranslation = getDetailKeyTranslation(detailKey, locale.value)
    let unit = null
    if (detail.key === 'elevationM' || detail.key === 'heightM') {
      unit = 'm'
    }
    return {
      ...detail,
      name: keyTranslation.key,
      icon: {
        id: detailKey.iconId,
        name: detailKey.iconName,
      },
      detailKey,
      unit,
    }
  })
})
</script>
