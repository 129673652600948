<template>
  <div
    class="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 bg-transparent w-screen sm:h-screen pointer-events-none"
  >
    <div
      v-if="showDebugInfo"
      class="fixed h-0.5 w-full top-[120px] bg-green-400 bg-opacity-50"
      style="min-height: 2px"
    >
      &nbsp;
    </div>
    <div
      v-if="showDebugInfo"
      class="fixed h-0.5 w-full top-1/2 bg-yellow-400 bg-opacity-50"
      style="min-height: 2px"
    >
      &nbsp;
    </div>
    <div
      v-if="showDebugInfo"
      class="fixed h-0.5 w-full bottom-1/3 bg-red-400 bg-opacity-50"
      style="min-height: 2px"
    >
      &nbsp;
    </div>
    <div class="sm:order-2 lg:col-span-2 h-auto">
      <div
        class="w-full bottom-0 sm:bottom-auto left-0 sm:top-0 right-0 pointer-events-auto"
      >
        <!-- Shortcuts -->
        <ShortcutsBar
          class="flex flex-no-wrap overflow-auto scrolling-touch items-start mb-0 gap-x-2 sm:gap-x-3 scrollbar-hidden px-2 sm:px-4 mt-safe-4"
        />
      </div>
    </div>
    <div class="sm:order-1">
      <div
        class="flex flex-col-reverse sm:flex-col sm:min-h-screen sm:max-h-screen sm:overflow-hidden"
      >
        <!-- main navigation bar-->
        <div
          class="h-16 rounded-b-2xl bg-gradient-to-r from-purple-500/80 via-blue-500/80 to-sky-500/80 flex mx-2 sm:mx-4 sm:mt-safe-4 py-3 px-2 sm:px-4 backdrop-blur-sm pointer-events-auto mb-safe-4 sm:safe-area-top"
          :class="[
            (selectedItemId || selectedHighlightId) && xs ? 'hidden' : '',
            selectedListId && xs ? '' : 'rounded-t-2xl',
          ]"
        >
          <!-- Search -->
          <div
            class="grow rounded-full bg-white bg-opacity-20 flex items-center justify-start order-1 sm:order-2 sm:ml-2 select-none"
          >
            <SvgIcon
              name="magnifying-glass"
              class="w-6 h-6 ml-3 stroke-white stroke-2 flex-shrink-0"
            />
            <label
              for="search"
              class="sr-only"
              :class="searchText && searchText.length > 0 ? 'hidden' : ''"
              >Search text</label
            >
            <input
              id="search"
              v-model.trim="searchText"
              type="search"
              class="mr-1 ml-2 px-0 text-white bg-transparent border-transparent focus:border-transparent focus:ring-0 placeholder-white placeholder-opacity-50 block"
              placeholder="Search"
              clearable
            />
            <SvgIcon
              v-if="searchText && searchText.length > 0"
              name="x-circle"
              class="w-7 h-7 mr-2 fill-white cursor-pointer flex-shrink-0"
              type="solid"
              @click="searchText = ''"
            />
          </div>
          <!-- Feed -->
          <NuxtLink
            class="w-10 h-10 rounded-full bg-opacity-10 bg-white flex items-center justify-center order-2 sm:order-3 ml-3 cursor-pointer select-none flex-shrink-0"
            aria-label="Feed"
            :to="localePath({ name: 'map-feed' })"
          >
            <SvgIcon name="sparkles" class="h-7 w-7 stroke-white" />
          </NuxtLink>
          <!-- Sidepanel / user -->
          <div
            class="w-10 h-10 rounded-full flex shrink-0 items-center justify-center order-4 sm:order-1 ml-3 mr-1 sm:ml-0 cursor-pointer bg-white bg-opacity-10 select-none"
            @click="openMapSidePanel()"
          >
            <SvgIcon
              v-if="!isAuthenticated || !profileImageSource"
              name="bars-3"
              type="outline"
              class="h-6 w-6 stroke-white"
            />
            <ClientOnly>
              <img
                v-if="isAuthenticated && profileImageSource"
                class="object-cover h-10 w-10 rounded-full"
                :src="profileImageSource"
                alt=""
              />
            </ClientOnly>
          </div>
        </div>
        <!-- Feed Result -->
        <FeedResult
          v-if="showFeedResult"
          :class="selectedItemId || selectedListId ? 'hidden' : ''"
        />
        <SearchResult
          v-if="selectedHighlightId"
          :class="selectedItemId || selectedListId ? 'hidden' : ''"
        />
        <!-- List Detail -->
        <ListResult
          v-if="selectedListId"
          :class="selectedItemId ? 'hidden' : ''"
        />
        <!-- Item Detail -->
        <ItemDetail v-if="selectedItemId" />
        <!-- Quick Search Result -->
        <QuickSearchResult
          v-if="searchText && searchText.length > 0"
          :class="selectedItemId ? 'hidden' : ''"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRound } from '@vueuse/math'
import {
  breakpointsTailwind,
  useBreakpoints,
  useWindowSize,
} from '@vueuse/core'
import ShortcutsBar from '~/components/ShortcutsBar.vue'

const { $log } = useNuxtApp()
$log('components:MapContent:setup:begin')

const breakpoints = useBreakpoints(breakpointsTailwind)

const xs = breakpoints.smaller('sm')

const appStore = useAppStore()
const { showDebugInfo } = storeToRefs(appStore)

const controllerStore = useControllerStore()

const { getItemTitle, getItemShortDescription, getMediumOgUrl } = useItem()

const itemStore = useItemStore()
const { selectedItemId } = storeToRefs(itemStore)

const listStore = useListStore()
const { selectedListId } = storeToRefs(listStore)

const feedStore = useFeedStore()
const { showFeedResult } = storeToRefs(feedStore)

const searchStore = useSearchStore()
const { searchText, selectedHighlightId } = storeToRefs(searchStore)

const { locale } = useI18n()
const localePath = useLocalePath()

const route = useRoute()
$log('components:MapContent:route', route)

const getRouteBaseName = useRouteBaseName()

// const getRouteBaseName = useRouteBaseName()
const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

const { height: windowHeight } = useWindowSize()

onMounted(() => {
  $log('components:MapContent:onMounted')

  controllerStore.setSnapTopPosition(120)
  controllerStore.setSnapMiddlePosition(useRound(windowHeight.value / 2).value)
  controllerStore.setSnapBottomPosition(
    useRound((windowHeight.value / 3) * 2).value
  )
})
onUnmounted(() => {
  $log('components:MapContent:onUnmounted')
})

const openMapSidePanel = () => {
  controllerStore.openMapSidePanel()
}

const seoTitleAppend = 'Quo Map'
const seoDescription = 'Map'

const seo = ref({
  title: seoTitleAppend,
  ogTitle: seoTitleAppend,
  description: seoDescription,
  ogDescription: seoDescription,
  ogImage: '/images/og-image.png',
  twitterTitle: seoTitleAppend,
  twitterDescription: seoDescription,
  twitterImage: '/images/og-image.png',
  twitterSite: '@quo_space',
})

const routeHandler = async () => {
  $log('components:MapContent:routeHandler:', route.fullPath)
  $log('components:MapContent:routeHandler:', baseRouteName.value)
  if (baseRouteName.value === 'map') {
    $log('components:MapContent:routeHandler:map:setSideViewOpen')
    controllerStore.setSideViewOpen(false)
    $log('components:MapContent:routeHandler:map:setSelectedItemId')
    itemStore.setSelectedItemId(null)
    $log('components:MapContent:routeHandler:map:setHighlightedItemIds')
    itemStore.setHighlightedItemIds([])
    selectedHighlightId.value = null
    listStore.clearSelectedList()
    feedStore.setShowFeedResult(false)
  } else if (baseRouteName.value === 'map-highlight-highlightUrlName') {
    const highlightUrlName = route.params.highlightUrlName
    if (showFeedResult.value) {
      feedStore.setShowFeedResult(false)
    }
    $log(
      'components:MapContent:routeHandler:map-highlight-highlightUrlName:highlightUrlName',
      highlightUrlName
    )
    searchStore.setSelectedHighlightId(highlightUrlName, locale)
    $log(
      'components:MapContent:routeHandler:map-highlight-highlightUrlName:selectedHighlightId',
      selectedHighlightId.value
    )
    if (selectedHighlightId.value) {
      controllerStore.setSideViewOpen(true)
      itemStore.setSelectedItemId(null)
      listStore.clearSelectedList()
    } else {
      // TODO: should redirect to /map
      controllerStore.setSideViewOpen(false)
      itemStore.setHighlightedItemIds([])
    }
  } else if (baseRouteName.value === 'map-place-nanoId-slug') {
    const nanoId = route.params.nanoId
    $log(
      'components:MapContent:routeHandler:map-place-nanoId-slug:nanoId',
      nanoId
    )
    if (nanoId && nanoId !== 'undefined') {
      $log(
        'components:MapContent:routeHandler:map-place-nanoId-slug:fetchItem:begin'
      )
      const item = await itemStore.getOrFetchItemByNanoId(nanoId)
      $log(
        'components:MapContent:routeHandler:map-place-nanoId-slug:fetchItem:end'
      )
      if (item) {
        itemStore.setSelectedItemId(item.id)
        controllerStore.setSideViewOpen(true)
        const title = getItemTitle(item, locale)
        if (title) {
          seo.value.title = `${title} - ${seoTitleAppend}`
          seo.value.ogTitle = `${title} - ${seoTitleAppend}`
          seo.value.twitterTitle = `${title} - ${seoTitleAppend}`
        }
        const shortDescription = getItemShortDescription(item, locale)
        if (shortDescription) {
          seo.value.description = shortDescription
          seo.value.ogDescription = shortDescription
          seo.value.twitterDescription = shortDescription
        }
        $log('components:MapContent:routeHandler:item', item)
        let mediumOgUrl = null
        if (item.media.length > 0) {
          mediumOgUrl = getMediumOgUrl(item.media[0])
        } else if (item.legacy_media.length > 0) {
          mediumOgUrl = item.legacy_media[0].url
        }
        $log('components:MapContent:routeHandler:mediumOgUrl', mediumOgUrl)
        if (mediumOgUrl) {
          seo.value.ogImage = mediumOgUrl
          seo.value.twitterImage = mediumOgUrl
          seo.value.twitterCard = 'summary_large_image'
        } else {
          seo.value.ogImage = null
          seo.value.twitterImage = null
          seo.value.twitterCard = 'summary'
        }
        $log('components:MapContent:routeHandler:seo', seo.value)
      }
    }
  } else if (baseRouteName.value === 'map-list-nanoId-slug') {
    itemStore.setSelectedItemId(null)
    selectedHighlightId.value = null
    const nanoId = route.params.nanoId
    $log('MapContent: routeHandler: map-list-nanoId-slug: nanoId: ', nanoId)
    if (nanoId && nanoId !== 'undefined') {
      const list = await listStore.getOrFetchListByNanoId(nanoId)
      if (list) {
        controllerStore.setSideViewOpen(true)
        listStore.setSelectedListByNanoId(nanoId)
      }
    }
  } else if (baseRouteName.value === 'map-feed') {
    $log('MapContent: routeHandler: map-feed')
    feedStore.setShowFeedResult(true)
    controllerStore.setSideViewOpen(true)
    itemStore.setSelectedItemId(null)
    listStore.clearSelectedList()
    itemStore.setHighlightedItemIds([])
  }
}

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
})

// TODO: #3 Check if useSeoMeta is working again, then remove useHead
const setHead = () => {
  // TODO: #44 is useHead allowed within this function?
  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs.lang,
    },
    title: seo.value.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: seo.value.description,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: seo.value.ogTitle,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: seo.value.ogDescription,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: seo.value.ogImage,
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: seo.value.twitterTitle,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: seo.value.twitterDescription,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: seo.value.twitterImage,
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: seo.value.twitterSite,
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: seo.value.twitterCard,
      },
    ],
  })
}

await routeHandler()
$log('components:MapContent:seo', seo.value)
// useServerSeoMeta(seo)
setHead()

const fullPath = computed(() => route.fullPath)

watch(fullPath, async (value) => {
  $log('components:MapContent:watch:fullPath', value)
  await routeHandler()
  $log('components:MapContent:watch:fullPath:seo', seo.value)
  // useSeoMeta(seo)
  setHead()
})

const profileStore = useProfileStore()
const { isAuthenticated, profile } = storeToRefs(profileStore)

const client = useSupabaseClient()
const profileImageSource = ref('')

// TODO: Why is this not working when rendered on server-side?
const downloadImage = async () => {
  try {
    if (!profile.value.avatarUrl) return
    const { data, error } = await client.storage
      .from('avatars')
      .download(profile.value.avatarUrl)
    if (error) {
      throw error
    }
    profileImageSource.value = URL.createObjectURL(data)
  } catch (error) {
    console.error('Error downloading image: ', error.message)
  }
}

await downloadImage()

watch(
  profile,
  () => {
    downloadImage()
  },
  { immediate: true, deep: true }
)

watch(selectedItemId, (value) => {
  $log('components:MapContent:watch:selectedItemId', value)
})

watch(selectedListId, (value) => {
  $log('components:MapContent:watch:selectedListId', value)
})

watch(selectedHighlightId, (value) => {
  $log('components:MapContent:watch:selectedHighlightId', value)
})
$log('components:MapContent:setup:end')
</script>

<style scoped>
.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@supports (padding: max(0px)) {
  .safe-area-bottom {
    margin-bottom: max(16px, env(safe-area-inset-bottom));
  }
  .safe-area-top {
    margin-top: max(16px, env(safe-area-inset-top));
  }
}
</style>
