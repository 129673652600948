import { useTagStore } from '~/stores/tag'
import { useIconStore } from '~/stores/icon'
import colors from '#tailwind-config/theme/colors'

export const mapSvgUrl = (type, tagId, colorMode) => {
  if (!type) return
  const pinia = useNuxtApp().$pinia

  const tagStore = useTagStore(pinia)

  const iconStore = useIconStore(pinia)

  const numericTagId = +tagId
  if (isNaN(numericTagId)) return

  const tag = tagStore.getTagById(Number(tagId))
  if (tag === undefined) return

  const icon = iconStore.getIconById(tag.iconId)
  if (icon === undefined) return

  const bgColorVar = colors.tag[tag.bgColor]

  let bgColor = colors.tag[tag.bgColor]

  if (bgColorVar.startsWith('var(')) {
    const bgColorVarName = bgColorVar.replace(/var\((--[^)]+)\)/g, '$1')
    bgColor = getComputedStyle(document.documentElement).getPropertyValue(
      bgColorVarName
    )
  }

  // const bgColor = colors.tag[tag.bgColor]
  const color = colorMode === 'dark' ? colors.gray[800] : colors.tag[tag.color]

  const borderColor = colorMode === 'dark' ? colors.gray[800] : colors.white

  const symbol = {
    NAME: 'no-name',
    WIDTH: '48px',
    HEIGHT: '48px',
    BACKGROUND: bgColor,
    FOREGROUND: color,
    ICON: icon.mini !== null ? icon.mini : '',
    BORDER: borderColor,
  }

  const baseSymbolSVG = `<svg width="WIDTH" height="HEIGHT" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" fill="currentColor" style="color: FOREGROUND;">
    <g>
      <circle fill="BACKGROUND" cx="13" cy="13" r="12.25" stroke="BORDER" stroke-width="1.5"></circle>
      <g transform="translate(3 3)">{insertSymbolSVG}</g>
    </g>
  </svg>`

  const baseSymbolSelected = `<svg width="WIDTH" height="HEIGHT" viewBox="0 0 32 44" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="BACKGROUND" fill-rule="nonzero">
        <g transform="translate(0.000000, -0.000000)">
          <path d="M16,0 C7.17866667,0 0,7.45433333 0,16.6191667 C0,29.6413333 14.496,43.087 15.1128889,43.6516667 C15.3671111,43.8845 15.6835556,44 16,44 C16.3164444,44 16.6328889,43.8845 16.8871111,43.6535 C17.504,43.087 32,29.6413333 32,16.6191667 C32,7.45433333 24.8213333,0 16,0 Z M16,28 C9.3832,28 4,22.6168 4,16 C4,9.3832 9.3832,4 16,4 C22.6168,4 28,9.3832 28,16 C28,22.6168 22.6168,28 16,28 Z"></path>
          <g transform="translate(4,4)">
            <circle stroke="FOREGROUND" fill="FOREGROUND" cx="12" cy="12" r="11.5"></circle>
            <g transform="translate(2,2)">
              {insertSymbolSVG}
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`

  let symbolSVG

  if (type === 'circle-symbol') {
    symbolSVG = baseSymbolSVG.replace(/{insertSymbolSVG}/, symbol.ICON)
  } else if (type === 'pin-symbol') {
    symbol.HEIGHT = '88px'
    symbol.WIDTH = '64px'

    symbolSVG = baseSymbolSelected.replace(/{insertSymbolSVG}/, symbol.ICON)
  }
  if (type === 'circle-dot') {
    symbolSVG = baseSymbolSVG.replace(/{insertSymbolSVG}/, '')
  }

  symbolSVG = symbolSVG.replace(/\b[A-Z]{4,}\b/g, function (all) {
    return Object.prototype.hasOwnProperty.call(symbol, all) ? symbol[all] : all
  })

  const blob = new Blob([symbolSVG], { type: 'image/svg+xml' })
  const url = URL.createObjectURL(blob)

  return url
}
